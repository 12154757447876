<template>
  <!--begin::PriceSearch step 3-->
  <div class="gray-box" v-if="Route && Transporter">
    <!--begin::Substep navigation bar-->
    <div class="white-box shadow-box py-6 px-12 mb-6" style="border-radius: 0">
      <v-stepper v-model="CurrentSubstep">
        <v-stepper-header>
          <v-stepper-step
            :step="SubstepThere"
            :complete="CurrentSubstep > SubstepThere"
          >
            {{
              $t("PRICE_SEARCH.STEP3.EXTRA_DATA.TITLE") +
                " (" +
                $t(
                  Route.Back.Enabled
                    ? "PRICE_SEARCH.STEP1.THERE"
                    : "PRICE_SEARCH.STEP1.JUST_THERE"
                ).toUpperCase() +
                ")"
            }}
          </v-stepper-step>
          <v-stepper-step
            v-if="Route.Back.Enabled"
            :step="SubstepBack"
            :complete="CurrentSubstep > SubstepBack"
          >
            {{
              $t("PRICE_SEARCH.STEP3.EXTRA_DATA.TITLE") +
                " (" +
                $t("PRICE_SEARCH.STEP1.BACK").toUpperCase() +
                ")"
            }}
          </v-stepper-step>
          <v-stepper-step
            v-if="!isAuthenticated || RegisterData.Done"
            :step="SubstepRegister"
            :complete="CurrentSubstep > SubstepRegister"
          >
            {{ $t("PRICE_SEARCH.STEP3.REGISTER_DATA.TITLE") }}
          </v-stepper-step>
          <v-stepper-step
            :step="SubstepFinish"
            :complete="CurrentSubstep > SubstepFinish"
          >
            {{ $t("PRICE_SEARCH.STEP3.FINISH.TITLE") }}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </div>
    <!--end::Substep navigation bar-->

    <div class="row px-12 pb-3">
      <!--begin:Substeps forms-->
      <div class="col-lg-9" @keydown.enter.prevent>
        <v-stepper v-model="CurrentSubstep">
          <v-stepper-items>
            <!--begin::ExtraData form for There-->
            <v-stepper-content :step="SubstepThere">
              <v-form
                ref="formStep3ExtraThere"
                v-model="FormStep3ExtraThereValid"
                lazy-validation
                class="form"
              >
                <v-expansion-panels
                  v-model="PanelsStep3ExtraThereOpen"
                  multiple
                  accordion
                >
                  <!--begin::Origin & Destination ExtraData for There-->
                  <v-expansion-panel
                    class="white-box shadow-box p-0 mb-6"
                    v-for="whichSide in ['Origin', 'Destination']"
                    :key="whichSide"
                  >
                    <v-expansion-panel-header
                      class="manager-subtitle font-size-h4"
                    >
                      <span>
                        {{
                          $t(
                            "PRICE_SEARCH.STEP3.EXTRA_DATA." +
                              whichSide.toUpperCase() +
                              "_DATA"
                          )
                        }}
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-6 border-top-200">
                      <div class="row">
                        <div class="form-group col-lg-6">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.TRADE_NAME") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            v-model="Route[whichSide + 'Address'].TradeName"
                            :placeholder="$t('FREQUENT_ADDRESSES.TRADE_NAME')"
                            required
                            :rules="[rules.required]"
                            :readonly="
                              parseInt(Route[whichSide + 'Address'].AddressID) >
                                0
                            "
                          />
                        </div>
                        <div class="col-lg-6 d-flex align-items-center">
                          <inline-svg
                            src="/media/icons/help-alt-5-primary.svg"
                          />
                          <span class="pl-3 font-size-sm">
                            {{ $t("FREQUENT_ADDRESSES.TRADE_NAME_INFO") }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-8 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.FULL_ADDRESS_2") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            :value="Route[whichSide + 'Address'].Street"
                            readonly
                          />
                        </div>
                        <div class="col-lg-4 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.POSTAL_CODE_2") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            :value="Route[whichSide + 'Address'].PostalCode"
                            readonly
                          />
                        </div>
                        <div class="col-lg-6 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.CITY") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            :value="Route[whichSide + 'Address'].City"
                            readonly
                          />
                        </div>
                        <div class="col-lg-6 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.PROVINCE") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            :value="
                              getProvinceName(
                                Route[whichSide + 'Address'].ProvinceID
                              )
                            "
                            readonly
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.CONTACT_NAME") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            v-model="Route[whichSide + 'Address'].ContactName"
                            :placeholder="$t('FREQUENT_ADDRESSES.CONTACT_NAME')"
                            required
                            :rules="[rules.required]"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.CONTACT_EMAIL") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            v-model="Route[whichSide + 'Address'].ContactEmail"
                            :placeholder="
                              $t('FREQUENT_ADDRESSES.CONTACT_EMAIL')
                            "
                            required
                            :rules="[rules.required, rules.email]"
                          >
                            <template v-slot:prepend-inner>
                              <inline-svg src="/media/icons/contact-gray.svg" />
                            </template>
                          </v-text-field>
                        </div>

                        <div class="col-lg-6 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.CONTACT_PHONE") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            v-model="Route[whichSide + 'Address'].ContactPhone"
                            :placeholder="
                              $t('FREQUENT_ADDRESSES.CONTACT_PHONE')
                            "
                            required
                            :rules="[rules.required]"
                          >
                            <template v-slot:prepend-inner>
                              <inline-svg src="/media/icons/phone-gray.svg" />
                            </template>
                          </v-text-field>
                        </div>
                      </div>

                      <div
                        v-if="
                          isAuthenticated &&
                            currentUserCompanyIsPremium &&
                            !Route[whichSide + 'Address'].AddressID
                        "
                        class="row"
                      >
                        <div class="col-lg-12 form-group">
                          <v-checkbox
                            v-model="Route[whichSide + 'Address'].SaveAddress"
                            style="margin-bottom: -1em"
                          >
                            <template v-slot:label>
                              <div class="pt-1 ml-3 text-dark">
                                <strong>
                                  {{
                                    $t(
                                      "PRICE_SEARCH.STEP3.EXTRA_DATA.SAVE_ADDRESS"
                                    )
                                  }}
                                </strong>
                                <br />
                                <span class="font-weight-normal">
                                  {{
                                    $t(
                                      "PRICE_SEARCH.STEP3.EXTRA_DATA.SAVE_ADDRESS_INFO"
                                    )
                                  }}
                                </span>
                              </div>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!--end::Origin & Destination ExtraData for There-->

                  <!--begin::VehiclesExtraData for There-->
                  <v-expansion-panel class="white-box shadow-box p-0 mb-6">
                    <v-expansion-panel-header
                      class="manager-subtitle font-size-h4"
                    >
                      <span>
                        {{ $t("PRICE_SEARCH.STEP3.EXTRA_DATA.VEHICLES_DATA") }}
                        <span v-if="Route.Back.Enabled">
                          ({{ $t("PRICE_SEARCH.STEP1.THERE").toUpperCase() }})
                        </span>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-6 border-top-200">
                      <p>
                        {{
                          $t("PRICE_SEARCH.STEP3.EXTRA_DATA.VEHICLES_DATA_INFO")
                        }}
                      </p>

                      <div
                        v-for="(vehicle2, indexV2) in Route.There.VehiclesStep2"
                        :key="'There_2_' + indexV2"
                      >
                        <label class="mb-6">
                          <inline-svg
                            src="/media/icons/car-primary.svg"
                            class="mr-3"
                          />
                          <strong>
                            {{ $t("PRICE_SEARCH.STEP1.VEHICLE") }}
                            {{ indexV2 + 1 }}:
                          </strong>
                          {{
                            "x" +
                              vehicle2.Quantity +
                              ", " +
                              vehicle2.Brand +
                              ", " +
                              vehicle2.Model +
                              ", " +
                              vehicle2.Version +
                              ", " +
                              vehicle2.Year +
                              ". " +
                              VehicleTypeNames[vehicle2.VehicleTypeID] +
                              ", " +
                              VehicleStatusNames[vehicle2.VehicleStatusID]
                          }}
                        </label>
                        <div
                          v-for="(vehicle, indexV) in Route.There.Vehicles"
                          :key="'There_1_' + indexV"
                        >
                          <div
                            v-if="
                              vehicle.VehicleModelID ===
                                vehicle2.VehicleModelID &&
                                vehicle.VehicleStatusID ===
                                  vehicle2.VehicleStatusID
                            "
                            class="row mb-6 pb-3 border-bottom-200"
                          >
                            <!--begin::Vehicle plate There-->
                            <div class="mb-9 col-lg-6">
                              <v-text-field
                                class="form-control"
                                v-model="Route.There.Vehicles[indexV].Plate"
                                :placeholder="$t('MY_VEHICLES.PLATE')"
                                required
                                :rules="[
                                  rules.required,
                                  rules.plateUniqueThere
                                ]"
                                @keyup="onKeyupVehiclePlate('There', indexV)"
                              />
                            </div>
                            <!--end::Vehicle plate There-->

                            <!--begin::Vehicle import There-->
                            <div class="col-lg-6">
                              <button
                                v-if="
                                  isAuthenticated && currentUserCompanyIsPremium
                                "
                                class="btn btn-pill btn-white button-filter button-form-primary px-6 py-1"
                                @click="
                                  openMyVehicleDialog('There', indexV, $event)
                                "
                              >
                                <inline-svg
                                  src="/media/icons/book-primary.svg"
                                  class="mr-3"
                                />
                                {{ $t("PRICE_SEARCH.STEP1.IMPORT_MY_VEHICLE") }}
                              </button>
                            </div>
                            <!--end::Vehicle import There-->

                            <!--begin::Vehicle services There-->
                            <div
                              v-if="
                                ServiceOptions.Origin.length > 0 ||
                                  ServiceOptions.Destination.length > 0
                              "
                              class="col-lg-12 px-6"
                            >
                              <h4>
                                <inline-svg
                                  src="/media/icons/servicios-extra-primary.svg"
                                  class="mr-3"
                                />
                                {{
                                  $t("PRICE_SEARCH.STEP3.EXTRA_DATA.SERVICES")
                                }}
                              </h4>
                              <div class="pt-6 border-top-200">
                                <div class="row">
                                  <div
                                    v-for="whichSideServices in [
                                      'Origin',
                                      'Destination'
                                    ]"
                                    :key="
                                      'There_Services_' +
                                        indexV +
                                        '_' +
                                        whichSideServices
                                    "
                                    class="col-lg-6"
                                  >
                                    <strong>
                                      {{
                                        $t(
                                          "PRICE_SEARCH.STEP3.EXTRA_DATA.SERVICES_" +
                                            whichSideServices.toUpperCase()
                                        )
                                      }}
                                    </strong>
                                    <div
                                      v-if="
                                        filterServiceOptionsByVehicleTypeID(
                                          'There',
                                          whichSideServices,
                                          vehicle2.VehicleTypeID
                                        ).length < 1
                                      "
                                      class="text-danger"
                                    >
                                      {{
                                        $t(
                                          "PRICE_SEARCH.STEP3.EXTRA_DATA.SERVICES_" +
                                            whichSideServices.toUpperCase() +
                                            "_NONE"
                                        )
                                      }}
                                    </div>

                                    <div v-else>
                                      <div
                                        v-for="aService in filterServiceOptionsByVehicleTypeID(
                                          'There',
                                          whichSideServices,
                                          vehicle2.VehicleTypeID
                                        )"
                                        :key="
                                          'There_Services_' +
                                            indexV +
                                            '_' +
                                            whichSideServices +
                                            '_' +
                                            aService.ServiceID
                                        "
                                      >
                                        <v-checkbox
                                          v-model="
                                            Route.There.Vehicles[indexV][
                                              'ServicesCheckbox' +
                                                whichSideServices
                                            ][aService.ServiceID]
                                          "
                                          style="margin-bottom: -2em"
                                          @click="
                                            onClickServiceCheckbox(
                                              'There',
                                              indexV,
                                              whichSideServices
                                            )
                                          "
                                        >
                                          <template v-slot:label>
                                            <div class="pt-1 ml-3 text-dark">
                                              <strong>
                                                {{ aService.Name }}
                                              </strong>
                                              {{
                                                "(" +
                                                  formatServicePrice(
                                                    aService.Price,
                                                    Transporter.Price.Currency
                                                  ) +
                                                  ")"
                                              }}
                                            </div>
                                          </template>
                                        </v-checkbox>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--end::Vehicle services There-->
                          </div>
                        </div>
                      </div>

                      <!--begin::SaveVehicles There-->
                      <div
                        v-if="isAuthenticated && currentUserCompanyIsPremium"
                        class="row"
                      >
                        <div class="col-lg-12 form-group">
                          <v-checkbox
                            v-model="Route.There.SaveVehicles"
                            style="margin-bottom: -1em"
                          >
                            <template v-slot:label>
                              <div class="pt-1 ml-3 text-dark">
                                <strong>
                                  {{
                                    $t(
                                      "PRICE_SEARCH.STEP3.EXTRA_DATA.SAVE_VEHICLES"
                                    )
                                  }}
                                </strong>
                                <br />
                                <span class="font-weight-normal">
                                  {{
                                    $t(
                                      "PRICE_SEARCH.STEP3.EXTRA_DATA.SAVE_VEHICLES_INFO"
                                    )
                                  }}
                                </span>
                              </div>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                      <!--end::SaveVehicles There-->
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!--end::VehiclesExtraData for There-->

                  <!--begin::DatesExtraData for There-->
                  <v-expansion-panel class="white-box shadow-box p-0 mb-6">
                    <v-expansion-panel-header
                      class="manager-subtitle font-size-h4"
                    >
                      <span>
                        {{ $t("PRICE_SEARCH.STEP3.EXTRA_DATA.DATES_DATA") }}
                        <span v-if="Route.Back.Enabled">
                          ({{ $t("PRICE_SEARCH.STEP1.THERE").toUpperCase() }})
                        </span>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-6 border-top-200">
                      <p>
                        <strong>
                          {{
                            $t("PRICE_SEARCH.STEP1.DESTINATION_DATE_DESIRED")
                          }}:
                        </strong>
                        {{
                          $t(
                            "PRICE_SEARCH.STEP1." +
                              Route.There.DestinationDateDesiredText
                          )
                        }}
                      </p>

                      <!--begin::OriginDate for There-->
                      <div class="row">
                        <div class="col-lg-6 form-group">
                          <label>
                            <inline-svg
                              src="/media/icons/calendar-add-primary.svg"
                              class="mr-3"
                            />
                            {{
                              $t(
                                "PRICE_SEARCH.STEP3.EXTRA_DATA.ORIGIN_DATE_DESIRED"
                              )
                            }}
                          </label>
                          <v-text-field
                            class="form-control bg-white"
                            v-model="Route.There.OriginDateDesiredFormatted"
                            readonly
                            required
                            :rules="[rules.required]"
                            @click="openOriginDateDesiredDialog('There')"
                          />
                        </div>
                        <div class="col-lg-6 d-flex align-items-center">
                          <span style="width: 1.5em">
                            <inline-svg
                              src="/media/icons/help-alt-5-primary.svg"
                            />
                          </span>
                          <span class="pl-3">
                            {{
                              $t(
                                "PRICE_SEARCH.STEP3.EXTRA_DATA.ORIGIN_DATE_DESIRED_INFO"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                      <!--end::OriginDate for There-->

                      <!--begin::DestinationDate for There-->
                      <div class="row">
                        <div class="col-lg-6 form-group">
                          <label>
                            <inline-svg
                              src="/media/icons/calendar-add-primary.svg"
                              class="mr-3"
                            />
                            {{
                              $t(
                                "PRICE_SEARCH.STEP3.EXTRA_DATA.DESTINATION_DATE_ESTIMATED"
                              )
                            }}
                          </label>
                          <v-text-field
                            class="form-control"
                            :value="formatDestinationDates('There')"
                            readonly
                          />
                        </div>
                        <div class="col-lg-6 d-flex align-items-center">
                          <span style="width: 1.5em">
                            <inline-svg
                              src="/media/icons/help-alt-5-primary.svg"
                            />
                          </span>
                          <span class="pl-3">
                            {{
                              $t(
                                "PRICE_SEARCH.STEP3.EXTRA_DATA.DESTINATION_DATE_ESTIMATED_INFO"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                      <!--end::DestinationDate for There-->

                      <!--begin::DestinationTime for There-->
                      <div class="row border-bottom-200">
                        <div class="col-lg-6 form-group mb-0">
                          <label class="mb-1 pb-0">
                            <inline-svg
                              src="/media/icons/time-alt-1-primary.svg"
                              class="mr-3"
                            />
                            {{
                              $t(
                                "PRICE_SEARCH.STEP3.EXTRA_DATA.DESTINATION_DATE_DESIRED_TIME"
                              )
                            }}
                          </label>
                          <v-radio-group
                            v-model="Route.There.DestinationDateDesiredTimeText"
                            mandatory
                            style="padding-top: 0 !important"
                            @change="saveDestinationDateDesiredTime('There')"
                          >
                            <v-radio value="ALL_DAY" class="my-0">
                              <template v-slot:label>
                                <div class="pt-2 pl-2">
                                  {{
                                    $t("PRICE_SEARCH.STEP3.EXTRA_DATA.ALL_DAY")
                                  }}
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="MORNING" class="my-0">
                              <template v-slot:label>
                                <div class="pt-2 pl-2">
                                  {{
                                    $t(
                                      "PRICE_SEARCH.STEP3.EXTRA_DATA.MORNING"
                                    ) +
                                      " (" +
                                      MorningStart +
                                      " - " +
                                      MorningEnd +
                                      ")"
                                  }}
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="AFTERNOON" class="my-0">
                              <template v-slot:label>
                                <div class="pt-2 pl-2">
                                  {{
                                    $t(
                                      "PRICE_SEARCH.STEP3.EXTRA_DATA.AFTERNOON"
                                    ) +
                                      " (" +
                                      AfternoonStart +
                                      " - " +
                                      AfternoonEnd +
                                      ")"
                                  }}
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                      <!--end::DestinationTime for There-->

                      <!--begin::Notes for There-->
                      <div class="row pt-6">
                        <div class="col-lg-12 form-group">
                          <label>
                            {{ $t("PRICE_SEARCH.STEP3.EXTRA_DATA.NOTES") }}
                            <span class="font-weight-normal">
                              ({{ $t("GENERIC.OPTIONAL") }})
                            </span>
                          </label>
                          <textarea
                            rows="3"
                            class="form-control"
                            v-model="Route.There.Notes"
                            :placeholder="
                              $t('PRICE_SEARCH.STEP3.EXTRA_DATA.NOTES_INFO')
                            "
                          ></textarea>
                        </div>
                      </div>
                      <!--end::Notes for There-->
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!--end::DatesExtraData for There-->
                </v-expansion-panels>

                <!--begin:Submit buttons for There-->
                <div class="my-3 w-100 text-center">
                  <button
                    class="btn btn-pill btn-white button-form button-form-primary mr-9"
                    @click="onClickBackButton"
                  >
                    <inline-svg
                      src="/media/icons/arrow-return-primary.svg"
                      class="mr-3"
                    />
                    {{ $t("GENERIC.BACK") }}
                  </button>

                  <button
                    class="btn btn-pill btn-tertiary button-form"
                    :disabled="!FormStep3ExtraThereValid"
                    @click="onSubmitFormStep3ExtraThere"
                  >
                    {{ $t("GENERIC.CONTINUE") }}
                  </button>
                </div>
                <!--end:Submit buttons for There-->
              </v-form>
            </v-stepper-content>
            <!--end::ExtraData form for There-->

            <!--begin::ExtraData form for Back (if enabled)-->
            <v-stepper-content v-if="Route.Back.Enabled" :step="SubstepBack">
              <v-form
                ref="formStep3ExtraBack"
                v-model="FormStep3ExtraBackValid"
                lazy-validation
                class="form"
              >
                <v-expansion-panels
                  v-model="PanelsStep3ExtraBackOpen"
                  multiple
                  accordion
                >
                  <!--begin::Origin & Destination ExtraData for Back-->
                  <!--Reversed There, readonly-->
                  <v-expansion-panel
                    class="white-box shadow-box p-0 mb-6"
                    v-for="whichSide in ['Destination', 'Origin']"
                    :key="whichSide"
                  >
                    <v-expansion-panel-header
                      class="manager-subtitle font-size-h4"
                    >
                      <span>
                        {{
                          whichSide === "Destination"
                            ? $t("PRICE_SEARCH.STEP3.EXTRA_DATA.ORIGIN_DATA")
                            : $t(
                                "PRICE_SEARCH.STEP3.EXTRA_DATA.DESTINATION_DATA"
                              )
                        }}
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-6 border-top-200">
                      <div class="row">
                        <div class="form-group col-lg-6">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.TRADE_NAME") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            :value="Route[whichSide + 'Address'].TradeName"
                            readonly
                          />
                        </div>
                        <div class="col-lg-6 d-flex align-items-center">
                          <inline-svg
                            src="/media/icons/help-alt-5-primary.svg"
                          />
                          <span class="pl-3 font-size-sm">
                            {{ $t("FREQUENT_ADDRESSES.TRADE_NAME_INFO") }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-8 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.FULL_ADDRESS_2") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            :value="Route[whichSide + 'Address'].Street"
                            readonly
                          />
                        </div>
                        <div class="col-lg-4 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.POSTAL_CODE_2") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            :value="Route[whichSide + 'Address'].PostalCode"
                            readonly
                          />
                        </div>
                        <div class="col-lg-6 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.CITY") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            :value="Route[whichSide + 'Address'].City"
                            readonly
                          />
                        </div>
                        <div class="col-lg-6 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.PROVINCE") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            :value="
                              getProvinceName(
                                Route[whichSide + 'Address'].ProvinceID
                              )
                            "
                            readonly
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.CONTACT_NAME") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            v-model="Route[whichSide + 'Address'].ContactName"
                            :placeholder="$t('FREQUENT_ADDRESSES.CONTACT_NAME')"
                            readonly
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.CONTACT_EMAIL") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            v-model="Route[whichSide + 'Address'].ContactEmail"
                            :placeholder="
                              $t('FREQUENT_ADDRESSES.CONTACT_EMAIL')
                            "
                            readonly
                          >
                            <template v-slot:prepend-inner>
                              <inline-svg src="/media/icons/contact-gray.svg" />
                            </template>
                          </v-text-field>
                        </div>

                        <div class="col-lg-6 form-group">
                          <label>
                            {{ $t("FREQUENT_ADDRESSES.CONTACT_PHONE") }}
                          </label>
                          <v-text-field
                            class="form-control"
                            v-model="Route[whichSide + 'Address'].ContactPhone"
                            :placeholder="
                              $t('FREQUENT_ADDRESSES.CONTACT_PHONE')
                            "
                            readonly
                          >
                            <template v-slot:prepend-inner>
                              <inline-svg src="/media/icons/phone-gray.svg" />
                            </template>
                          </v-text-field>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!--end::Origin & Destination ExtraData for Back-->

                  <!--begin::VehiclesExtraData for Back-->
                  <v-expansion-panel class="white-box shadow-box p-0 mb-6">
                    <v-expansion-panel-header
                      class="manager-subtitle font-size-h4"
                    >
                      <span>
                        {{
                          $t("PRICE_SEARCH.STEP3.EXTRA_DATA.VEHICLES_DATA") +
                            " (" +
                            $t("PRICE_SEARCH.STEP1.BACK").toUpperCase() +
                            ")"
                        }}
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-6 border-top-200">
                      <p v-if="Route.Back.SameVehicles" class="mb-6">
                        {{ $t("PRICE_SEARCH.STEP1.SAME_VEHICLES_2") }}
                      </p>

                      <div
                        v-for="(vehicle2, indexV2) in Route.Back.VehiclesStep2"
                        :key="'Back_2_' + indexV2"
                      >
                        <label class="mb-6">
                          <inline-svg
                            src="/media/icons/car-primary.svg"
                            class="mr-3"
                          />
                          <strong>
                            {{ $t("PRICE_SEARCH.STEP1.VEHICLE") }}
                            {{ indexV2 + 1 }}:
                          </strong>
                          {{
                            "x" +
                              vehicle2.Quantity +
                              ", " +
                              vehicle2.Brand +
                              ", " +
                              vehicle2.Model +
                              ", " +
                              vehicle2.Version +
                              ", " +
                              vehicle2.Year +
                              ". " +
                              VehicleTypeNames[vehicle2.VehicleTypeID] +
                              ", " +
                              VehicleStatusNames[vehicle2.VehicleStatusID]
                          }}
                        </label>
                        <div
                          v-for="(vehicle, indexV) in Route.Back.Vehicles"
                          :key="'Back_1_' + indexV"
                        >
                          <div
                            v-if="
                              vehicle.VehicleModelID ===
                                vehicle2.VehicleModelID &&
                                vehicle.VehicleStatusID ===
                                  vehicle2.VehicleStatusID
                            "
                            class="row mb-6 pb-3 border-bottom-200"
                          >
                            <!--begin::Vehicle plate Back-->
                            <!--Readonly if same as There-->
                            <div class="mb-9 col-lg-6">
                              <v-text-field
                                class="form-control"
                                v-model="Route.Back.Vehicles[indexV].Plate"
                                :placeholder="$t('MY_VEHICLES.PLATE')"
                                required
                                :rules="[rules.required, rules.plateUniqueBack]"
                                :readonly="Route.Back.SameVehicles"
                                @keyup="onKeyupVehiclePlate('Back', indexV)"
                              />
                            </div>
                            <!--end::Vehicle plate Back-->

                            <!--begin::Vehicle import Back-->
                            <!--Only if not same as There-->
                            <div class="col-lg-6">
                              <button
                                v-if="
                                  isAuthenticated &&
                                    currentUserCompanyIsPremium &&
                                    !Route.Back.SameVehicles
                                "
                                class="btn btn-pill btn-white button-filter button-form-primary px-6 py-1"
                                @click="
                                  openMyVehicleDialog('Back', indexV, $event)
                                "
                              >
                                <inline-svg
                                  src="/media/icons/book-primary.svg"
                                  class="mr-3"
                                />
                                {{ $t("PRICE_SEARCH.STEP1.IMPORT_MY_VEHICLE") }}
                              </button>
                            </div>
                            <!--end::Vehicle import Back-->

                            <!--begin::Vehicle services Back-->
                            <!--Side reversed from There-->
                            <div
                              v-if="
                                ServiceOptions.Origin.length > 0 ||
                                  ServiceOptions.Destination.length > 0
                              "
                              class="col-lg-12 px-6"
                            >
                              <h4>
                                <inline-svg
                                  src="/media/icons/servicios-extra-primary.svg"
                                  class="mr-3"
                                />
                                {{
                                  $t("PRICE_SEARCH.STEP3.EXTRA_DATA.SERVICES")
                                }}
                              </h4>
                              <div class="pt-6 border-top-200">
                                <div class="row">
                                  <div
                                    v-for="whichSideServices in [
                                      'Origin',
                                      'Destination'
                                    ]"
                                    :key="
                                      'Back_Services_' +
                                        indexV +
                                        '_' +
                                        whichSideServices
                                    "
                                    class="col-lg-6"
                                  >
                                    <strong>
                                      {{
                                        $t(
                                          "PRICE_SEARCH.STEP3.EXTRA_DATA.SERVICES_" +
                                            whichSideServices.toUpperCase()
                                        )
                                      }}
                                    </strong>
                                    <div
                                      v-if="
                                        filterServiceOptionsByVehicleTypeID(
                                          'Back',
                                          whichSideServices,
                                          vehicle2.VehicleTypeID
                                        ).length < 1
                                      "
                                      class="text-danger"
                                    >
                                      {{
                                        $t(
                                          "PRICE_SEARCH.STEP3.EXTRA_DATA.SERVICES_" +
                                            whichSideServices.toUpperCase() +
                                            "_NONE"
                                        )
                                      }}
                                    </div>

                                    <div v-else>
                                      <div
                                        v-for="aService in filterServiceOptionsByVehicleTypeID(
                                          'Back',
                                          whichSideServices,
                                          vehicle2.VehicleTypeID
                                        )"
                                        :key="
                                          'Back_Services_' +
                                            indexV +
                                            '_' +
                                            whichSideServices +
                                            '_' +
                                            aService.ServiceID
                                        "
                                      >
                                        <v-checkbox
                                          v-model="
                                            Route.Back.Vehicles[indexV][
                                              'ServicesCheckbox' +
                                                whichSideServices
                                            ][aService.ServiceID]
                                          "
                                          style="margin-bottom: -2em"
                                          @click="
                                            onClickServiceCheckbox(
                                              'Back',
                                              indexV,
                                              whichSideServices
                                            )
                                          "
                                        >
                                          <template v-slot:label>
                                            <div class="pt-1 ml-3 text-dark">
                                              <strong>
                                                {{ aService.Name }}
                                              </strong>
                                              {{
                                                "(" +
                                                  formatServicePrice(
                                                    aService.Price,
                                                    Transporter.Price.Currency
                                                  ) +
                                                  ")"
                                              }}
                                            </div>
                                          </template>
                                        </v-checkbox>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--end::Vehicle services Back-->
                          </div>
                        </div>
                      </div>

                      <!--begin::SaveVehicles Back-->
                      <div
                        v-if="isAuthenticated && currentUserCompanyIsPremium"
                        class="row"
                      >
                        <div class="col-lg-12 form-group">
                          <v-checkbox
                            v-model="Route.Back.SaveVehicles"
                            style="margin-bottom: -1em"
                          >
                            <template v-slot:label>
                              <div class="pt-1 ml-3 text-dark">
                                <strong>
                                  {{
                                    $t(
                                      "PRICE_SEARCH.STEP3.EXTRA_DATA.SAVE_VEHICLES"
                                    )
                                  }}
                                </strong>
                                <br />
                                <span class="font-weight-normal">
                                  {{
                                    $t(
                                      "PRICE_SEARCH.STEP3.EXTRA_DATA.SAVE_VEHICLES_INFO"
                                    )
                                  }}
                                </span>
                              </div>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                      <!--end::SaveVehicles Back-->
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!--end::VehiclesExtraData for Back-->

                  <!--begin::DatesExtraData for Back-->
                  <v-expansion-panel class="white-box shadow-box p-0 mb-6">
                    <v-expansion-panel-header
                      class="manager-subtitle font-size-h4"
                    >
                      <span>
                        {{
                          $t("PRICE_SEARCH.STEP3.EXTRA_DATA.DATES_DATA") +
                            " (" +
                            $t("PRICE_SEARCH.STEP1.BACK").toUpperCase() +
                            ")"
                        }}
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-6 border-top-200">
                      <p>
                        <strong>
                          {{
                            $t("PRICE_SEARCH.STEP1.DESTINATION_DATE_DESIRED")
                          }}:
                        </strong>
                        {{
                          $t(
                            "PRICE_SEARCH.STEP1." +
                              Route.Back.DestinationDateDesiredText
                          )
                        }}
                      </p>

                      <!--begin::OriginDate for Back-->
                      <div class="row">
                        <div class="col-lg-6 form-group">
                          <label>
                            <inline-svg
                              src="/media/icons/calendar-add-primary.svg"
                              class="mr-3"
                            />
                            {{
                              $t(
                                "PRICE_SEARCH.STEP3.EXTRA_DATA.ORIGIN_DATE_DESIRED_BACK"
                              )
                            }}
                          </label>
                          <select
                            class="form-control select-down"
                            v-model="Route.Back.OriginDateDesiredText"
                            required
                            @click.stop="changeOriginDateDesiredBack"
                            @change="changeOriginDateDesiredBack"
                          >
                            <option
                              value="WHEN_THERE"
                              class="text-primary"
                              @click="setOriginDateDesiredBackWhenThere"
                            >
                              {{
                                $t(
                                  "PRICE_SEARCH.STEP3.EXTRA_DATA.ORIGIN_DATE_DESIRED_BACK_THERE"
                                )
                              }}
                            </option>
                            <option
                              value="SPECIFIC_DATE"
                              class="text-primary"
                              @click="openOriginDateDesiredDialog('Back')"
                            >
                              {{ $t("PRICE_SEARCH.STEP1.SPECIFIC_DATE") }}
                              <span
                                v-if="
                                  Route.Back.OriginDateDesiredText ===
                                    'SPECIFIC_DATE' &&
                                    Route.Back.OriginDateDesiredFormatted
                                      .length > 0
                                "
                              >
                                ({{ Route.Back.OriginDateDesiredFormatted }})
                              </span>
                            </option>
                          </select>
                        </div>
                        <div class="col-lg-6 d-flex align-items-center">
                          <span style="width: 1.5em">
                            <inline-svg
                              src="/media/icons/help-alt-5-primary.svg"
                            />
                          </span>
                          <span class="pl-3">
                            {{
                              $t(
                                "PRICE_SEARCH.STEP3.EXTRA_DATA.ORIGIN_DATE_DESIRED_INFO"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                      <!--end::OriginDate for Back-->

                      <!--begin::DestinationDate for Back-->
                      <div class="row">
                        <div class="col-lg-6 form-group">
                          <label>
                            <inline-svg
                              src="/media/icons/calendar-add-primary.svg"
                              class="mr-3"
                            />
                            {{
                              $t(
                                "PRICE_SEARCH.STEP3.EXTRA_DATA.DESTINATION_DATE_ESTIMATED"
                              )
                            }}
                          </label>
                          <v-text-field
                            class="form-control"
                            :value="formatDestinationDates('Back')"
                            readonly
                          />
                        </div>
                        <div class="col-lg-6 d-flex align-items-center">
                          <span style="width: 1.5em">
                            <inline-svg
                              src="/media/icons/help-alt-5-primary.svg"
                            />
                          </span>
                          <span class="pl-3">
                            {{
                              $t(
                                "PRICE_SEARCH.STEP3.EXTRA_DATA.DESTINATION_DATE_ESTIMATED_INFO"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                      <!--end::DestinationDate for Back-->

                      <!--begin::DestinationTime for Back-->
                      <div class="row border-bottom-200">
                        <div class="col-lg-6 form-group mb-0">
                          <label class="mb-1 pb-0">
                            <inline-svg
                              src="/media/icons/time-alt-1-primary.svg"
                              class="mr-3"
                            />
                            {{
                              $t(
                                "PRICE_SEARCH.STEP3.EXTRA_DATA.DESTINATION_DATE_DESIRED_TIME"
                              )
                            }}
                          </label>
                          <v-radio-group
                            v-model="Route.Back.DestinationDateDesiredTimeText"
                            mandatory
                            style="padding-top: 0 !important"
                            @change="saveDestinationDateDesiredTime('Back')"
                          >
                            <v-radio value="ALL_DAY" class="my-0">
                              <template v-slot:label>
                                <div class="pt-2 pl-2">
                                  {{
                                    $t("PRICE_SEARCH.STEP3.EXTRA_DATA.ALL_DAY")
                                  }}
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="MORNING" class="my-0">
                              <template v-slot:label>
                                <div class="pt-2 pl-2">
                                  {{
                                    $t(
                                      "PRICE_SEARCH.STEP3.EXTRA_DATA.MORNING"
                                    ) +
                                      " (" +
                                      MorningStart +
                                      " - " +
                                      MorningEnd +
                                      ")"
                                  }}
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="AFTERNOON" class="my-0">
                              <template v-slot:label>
                                <div class="pt-2 pl-2">
                                  {{
                                    $t(
                                      "PRICE_SEARCH.STEP3.EXTRA_DATA.AFTERNOON"
                                    ) +
                                      " (" +
                                      AfternoonStart +
                                      " - " +
                                      AfternoonEnd +
                                      ")"
                                  }}
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                      <!--end::DestinationTime for Back-->

                      <!--begin::Notes for Back-->
                      <div class="row pt-6">
                        <div class="col-lg-12 form-group">
                          <label>
                            {{ $t("PRICE_SEARCH.STEP3.EXTRA_DATA.NOTES") }}
                            <span class="font-weight-normal">
                              ({{ $t("GENERIC.OPTIONAL") }})
                            </span>
                          </label>
                          <textarea
                            rows="3"
                            class="form-control"
                            v-model="Route.Back.Notes"
                            :placeholder="
                              $t('PRICE_SEARCH.STEP3.EXTRA_DATA.NOTES_INFO')
                            "
                          ></textarea>
                        </div>
                      </div>
                      <!--end::Notes for Back-->
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!--end::DatesExtraData for Back-->
                </v-expansion-panels>

                <!--begin:Submit buttons for Back-->
                <div class="my-3 w-100 text-center">
                  <button
                    class="btn btn-pill btn-white button-form button-form-primary mr-9"
                    @click="onClickBackButton"
                  >
                    <inline-svg
                      src="/media/icons/arrow-return-primary.svg"
                      class="mr-3"
                    />
                    {{ $t("GENERIC.BACK") }}
                  </button>

                  <button
                    class="btn btn-pill btn-tertiary button-form"
                    :disabled="!FormStep3ExtraBackValid"
                    @click="onSubmitFormStep3ExtraBack"
                  >
                    {{ $t("GENERIC.CONTINUE") }}
                  </button>
                </div>
                <!--end:Submit buttons for Back-->
              </v-form>
            </v-stepper-content>
            <!--end::ExtraData form for Back-->

            <!--begin::RegisterData (if needed)-->
            <v-stepper-content
              v-if="!isAuthenticated || RegisterData.Done"
              :step="SubstepRegister"
            >
              <div class="white-box shadow-box pt-6">
                <h3>
                  {{ $t("PRICE_SEARCH.STEP3.REGISTER_DATA.TITLE_2") }}
                </h3>

                <div class="row my-6">
                  <div class="col-lg-6 primary-box">
                    <div class="primary-box-header">
                      <div class="row">
                        <div class="col-lg-6 p-3">
                          <h4 class="mb-0 ml-6">
                            <strong>{{ RegisterSubscription.Name }}</strong>
                          </h4>
                        </div>
                        <div class="col-lg-6 p-3 text-right">
                          <h4
                            class="mb-0 pt-0 pr-6"
                            v-html="RegisterSubscription.Price"
                          ></h4>
                        </div>
                      </div>
                    </div>
                    <div class="primary-box-body">
                      <ul>
                        <li
                          v-for="(descriLine,
                          d) in RegisterSubscription.Description"
                          :key="'Subscri_' + d"
                        >
                          {{ descriLine }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <v-form
                  ref="formStep3ExtraRegister"
                  v-model="FormStep3ExtraRegisterValid"
                  lazy-validation
                  class="form"
                >
                  <div class="pl-1 mb-2">
                    <v-checkbox
                      v-model="RegisterData.Copy"
                      style="margin-bottom: -2em"
                      @click="onClickRegisterCopyCheckbox"
                    >
                      <template v-slot:label>
                        <div class="pt-1 ml-3 text-dark">
                          {{ $t("PRICE_SEARCH.STEP3.REGISTER_DATA.COPY") }}
                        </div>
                      </template>
                    </v-checkbox>
                  </div>

                  <!--begin::RegisterUserData-->
                  <div class="manager-subtitle">
                    1. {{ $t("MY_PROFILE.SUBTITLE") }}
                  </div>

                  <div v-if="!RegisterData.Done" class="row">
                    <div class="form-group col-lg-4">
                      <label>
                        {{ $t("COMPANIES.COMPANY_CLASS") }}
                      </label>
                      <select
                        class="form-control select-down"
                        v-model="RegisterData.CompanyClassID"
                        required
                      >
                        <option
                          v-for="cc in CompanyClassOptions"
                          :key="cc.CompanyClassID"
                          :value="cc.CompanyClassID"
                          class="text-primary"
                        >
                          {{ cc.Name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label>
                        {{ $t("MY_PROFILE.FULLNAME") }}
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.Name"
                        :placeholder="$t('MY_PROFILE.FULLNAME')"
                        :rules="[rules.required]"
                        required
                        :readonly="RegisterData.Done"
                      />
                    </div>

                    <div class="form-group col-lg-6">
                      <label>
                        {{ $t("MY_PROFILE.PHONE") }}
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.Phone"
                        :placeholder="$t('MY_PROFILE.PHONE')"
                        :rules="[rules.required]"
                        required
                        :readonly="RegisterData.Done"
                      >
                        <template v-slot:prepend-inner>
                          <inline-svg src="/media/icons/phone-gray.svg" />
                        </template>
                      </v-text-field>
                    </div>

                    <div class="form-group col-lg-6">
                      <label>
                        {{ $t("MY_PROFILE.EMAIL") }}
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.Email"
                        :placeholder="$t('MY_PROFILE.EMAIL')"
                        :rules="[rules.required, rules.email]"
                        required
                        :readonly="RegisterData.Done"
                      >
                        <template v-slot:prepend-inner>
                          <inline-svg src="/media/icons/contact-gray.svg" />
                        </template>
                      </v-text-field>
                    </div>

                    <div class="col-lg-6 d-flex align-items-center">
                      <inline-svg src="/media/icons/help-alt-5-primary.svg" />
                      <span class="pl-3">
                        {{ $t("USERS.NEW_EMAIL_INFO") }}
                      </span>
                    </div>
                  </div>
                  <!--end::RegisterUserData-->

                  <!--begin::RegisterCompanyData-->
                  <div class="manager-subtitle">
                    2. {{ $t("COMPANIES.SUBTITLE_FORM_BASIC") }}
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label>
                        {{ $t("COMPANIES.FISCAL_ID") }}
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.FiscalID"
                        :placeholder="$t('COMPANIES.FISCAL_ID')"
                        :rules="[rules.required]"
                        required
                        :readonly="RegisterData.Done"
                      />
                    </div>

                    <div class="form-group col-lg-6">
                      <label>
                        {{ $t("COMPANIES.FISCAL_NAME") }}
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.FiscalName"
                        :placeholder="$t('COMPANIES.FISCAL_NAME_INFO')"
                        :rules="[rules.required]"
                        required
                        :readonly="RegisterData.Done"
                      />
                    </div>

                    <div
                      v-if="RegisterData.CompanyClassID > 2"
                      class="form-group col-lg-6"
                    >
                      <label>
                        {{ $t("COMPANIES.TRADE_NAME") }}
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.TradeName"
                        :placeholder="$t('COMPANIES.TRADE_NAME')"
                        :rules="[rules.required]"
                        required
                        :readonly="RegisterData.Done"
                      />
                    </div>

                    <div
                      v-if="RegisterData.CompanyClassID > 2"
                      class="form-group col-lg-6"
                    >
                      <label>
                        {{ $t("COMPANIES.BRAND_NAME") }}
                        <span class="font-weight-normal">
                          ({{ $t("GENERIC.OPTIONAL") }})
                        </span>
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.BrandName"
                        :placeholder="$t('COMPANIES.BRAND_NAME')"
                        :readonly="RegisterData.Done"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-8">
                      <label>
                        {{ $t("COMPANIES.FULL_ADDRESS") }}
                      </label>
                      <v-text-field
                        v-if="RegisterData.Done"
                        class="form-control"
                        :value="RegisterData.Street"
                        readonly
                      />

                      <VueGoogleAutocomplete
                        v-else
                        ref="autocompleteRegisterDataAddress"
                        id="autocompleteRegisterDataAddress"
                        country="es"
                        :fields="[
                          'address_components',
                          'geometry',
                          'id',
                          'name'
                        ]"
                        v-model="RegisterData.Street"
                        :rules="[rules.required]"
                        required
                        classname="form-control"
                        placeholder=""
                        @placechanged="onAutocompleteRegisterPlaceChanged"
                        @inputChange="onAutocompleteRegisterInputChange"
                      />
                      <div
                        v-if="RegisterData.Street.trim().length < 1"
                        class="v-text-field__details pt-2 pl-3 pb-0 m-0"
                      >
                        <div
                          class="v-messages theme--light error--text"
                          role="alert"
                        >
                          <div class="v-messages__wrapper">
                            <div class="v-messages__message">
                              {{ $t("VALIDATION.REQUIRED_FIELD") }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-4">
                      <label>
                        {{ $t("COMPANIES.POSTAL_CODE") }}
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.PostalCode"
                        :placeholder="$t('COMPANIES.POSTAL_CODE')"
                        required
                        :rules="[rules.required]"
                        @keyup="onRegisterPostalCodeChanged"
                        :readonly="RegisterData.Done"
                      />
                    </div>

                    <div class="form-group col-lg-6">
                      <label>
                        {{ $t("COMPANIES.CITY") }}
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.City"
                        :placeholder="$t('COMPANIES.CITY')"
                        required
                        :rules="[rules.required]"
                        :readonly="RegisterData.Done"
                      />
                    </div>

                    <div class="form-group col-lg-6">
                      <label>
                        {{ $t("COMPANIES.PROVINCE") }}
                      </label>
                      <v-text-field
                        v-if="RegisterData.Done"
                        class="form-control"
                        :value="getProvinceName(RegisterData.ProvinceID)"
                        readonly
                      />

                      <select
                        v-else
                        class="form-control select-down"
                        v-model="RegisterData.ProvinceID"
                        required
                        :rules="[rules.required]"
                      >
                        <option
                          v-for="p in ProvinceOptions"
                          :key="p.ProvinceID"
                          :value="p.ProvinceID"
                          class="text-primary"
                        >
                          {{ p.Name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!--end::RegisterCompanyData-->

                  <!--begin::RegisterContactData-->
                  <div class="manager-subtitle mb-0">
                    3.
                    {{ $t("REGISTER.CONTACT_DATA") }}
                  </div>

                  <div class="d-flex align-items-center mb-3">
                    <inline-svg src="/media/icons/help-alt-5-primary.svg" />
                    <span class="pl-3">
                      {{ $t("REGISTER.CONTACT_DATA_INFO.2") }}
                    </span>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label>
                        {{ $t("COMPANIES.CONTACT_NAME") }}
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.ContactName"
                        :placeholder="$t('COMPANIES.CONTACT_NAME')"
                        :rules="[rules.required]"
                        required
                        :readonly="RegisterData.Done"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label>
                        {{ $t("COMPANIES.CONTACT_EMAIL") }}
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.ContactEmail"
                        :placeholder="$t('COMPANIES.CONTACT_EMAIL')"
                        :rules="[rules.required, rules.email]"
                        required
                        :readonly="RegisterData.Done"
                      >
                        <template v-slot:prepend-inner>
                          <inline-svg src="/media/icons/contact-gray.svg" />
                        </template>
                      </v-text-field>
                    </div>

                    <div class="form-group col-lg-6">
                      <label>
                        {{ $t("COMPANIES.CONTACT_PHONE") }}
                      </label>
                      <v-text-field
                        class="form-control"
                        v-model="RegisterData.ContactPhone"
                        :placeholder="$t('COMPANIES.CONTACT_PHONE')"
                        :rules="[rules.required]"
                        required
                        :readonly="RegisterData.Done"
                      >
                        <template v-slot:prepend-inner>
                          <inline-svg src="/media/icons/phone-gray.svg" />
                        </template>
                      </v-text-field>
                    </div>
                  </div>
                  <!--end::RegisterContactData-->

                  <div v-if="!RegisterData.Done" class="pl-1 mb-2">
                    <v-checkbox
                      v-model="RegisterData.Accept"
                      required
                      :rules="[rules.required]"
                    >
                      <template v-slot:label>
                        <div class="pt-1 ml-3 text-dark">
                          {{ $t("GENERIC.ACCEPT_CONDITIONS_1") }}
                          <a
                            href="/legal/terms"
                            target="_blank"
                            @click.stop
                            class="font-weight-bolder"
                          >
                            {{ $t("GENERIC.ACCEPT_CONDITIONS_2") }}
                          </a>
                        </div>
                      </template>
                    </v-checkbox>
                  </div>

                  <!--begin:Submit buttons for Register-->
                  <div class="my-3 w-100 text-center">
                    <button
                      class="btn btn-pill btn-white button-form button-form-primary mr-9"
                      @click="onClickBackButton"
                    >
                      <inline-svg
                        src="/media/icons/arrow-return-primary.svg"
                        class="mr-3"
                      />
                      {{ $t("GENERIC.BACK") }}
                    </button>

                    <button
                      v-if="RegisterData.Done"
                      class="btn btn-pill btn-tertiary button-form"
                      @click="CurrentSubstep = SubstepFinish"
                    >
                      {{ $t("GENERIC.CONTINUE") }}
                    </button>

                    <button
                      v-else
                      class="btn btn-pill btn-tertiary button-form"
                      :disabled="
                        !FormStep3ExtraRegisterValid ||
                          !RegisterData.Accept ||
                          FormStep3ExtraRegisterProcessing
                      "
                      @click="onSubmitFormStep3Register"
                    >
                      {{ $t("REGISTER.CONTINUE_FREE") }}
                    </button>
                  </div>
                  <!--end:Submit buttons for Register-->
                </v-form>
              </div>
            </v-stepper-content>
            <!--end::RegisterData-->

            <!--begin::Finish form-->
            <v-stepper-content :step="SubstepFinish">
              <v-form
                ref="formStep3ExtraFinish"
                v-model="FormStep3ExtraFinishValid"
                lazy-validation
                class="form"
              >
                <v-expansion-panels
                  v-model="PanelsStep3ExtraFinishOpen"
                  multiple
                  accordion
                >
                  <!--begin::OtherData-->
                  <v-expansion-panel
                    v-if="isAuthenticated"
                    class="white-box shadow-box p-0 mb-6"
                  >
                    <v-expansion-panel-header
                      class="manager-subtitle font-size-h4"
                    >
                      <span>
                        {{ $t("PRICE_SEARCH.STEP3.FINISH.OTHER_DATA") }}
                        <small class="font-weight-normal">
                          ({{ $t("GENERIC.OPTIONAL") }})
                        </small>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-6 border-top-200">
                      <!--begin::Observers-->
                      <div class="row">
                        <div class="col-lg-12 form-group mb-0">
                          <label
                            :title="
                              $t('PRICE_SEARCH.STEP3.FINISH.OBSERVERS_INFO')
                            "
                          >
                            {{ $t("PRICE_SEARCH.STEP3.FINISH.OBSERVERS") }}
                            <inline-svg
                              src="/media/icons/help-alt-5-primary.svg"
                              class="ml-2"
                            />
                          </label>
                        </div>
                        <div class="col-lg-1 pt-3 text-center">
                          <span class="action-icon" @click="openObserverDialog">
                            <inline-svg src="/media/icons/add-primary.svg" />
                          </span>

                          <!--begin::Observer dialog-->
                          <v-dialog
                            v-model="ObserverDialog.Show"
                            transition="dialog-bottom-transition"
                            max-width="700px"
                          >
                            <v-card>
                              <div
                                class="bg-primary text-white font-size-h5 py-3 px-7"
                              >
                                {{ $t("OBSERVERS.TITLE_ADD") }}
                              </div>

                              <v-card-text class="pt-6 pb-3">
                                <v-form
                                  ref="formObserverDialog"
                                  v-model="ObserverDialog.Valid"
                                  lazy-validation
                                  class="form"
                                >
                                  <div class="row">
                                    <div class="form-group col-lg-6">
                                      <label>
                                        {{ $t("MY_PROFILE.NICK") }}
                                      </label>
                                      <v-text-field
                                        class="form-control"
                                        v-model="ObserverDialog.Nick"
                                        :placeholder="$t('MY_PROFILE.NICK')"
                                        required
                                        :rules="[rules.required]"
                                      />
                                    </div>

                                    <div class="form-group col-lg-6">
                                      <label>
                                        {{ $t("MY_PROFILE.FULLNAME") }}
                                      </label>
                                      <v-text-field
                                        class="form-control"
                                        v-model="ObserverDialog.Name"
                                        :placeholder="$t('MY_PROFILE.FULLNAME')"
                                        required
                                        :rules="[rules.required]"
                                      />
                                    </div>

                                    <div class="form-group col-lg-6">
                                      <label>
                                        {{ $t("OBSERVERS.NICK_COMPANY") }}
                                      </label>
                                      <v-text-field
                                        class="form-control"
                                        v-model="ObserverDialog.NickCompany"
                                        :placeholder="
                                          $t('OBSERVERS.NICK_COMPANY')
                                        "
                                        required
                                        :rules="[rules.required]"
                                      />
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="form-group col-lg-6">
                                      <label>
                                        {{ $t("MY_PROFILE.EMAIL") }}
                                      </label>
                                      <v-text-field
                                        class="form-control"
                                        v-model="ObserverDialog.Email"
                                        :placeholder="$t('MY_PROFILE.EMAIL')"
                                        :rules="[rules.required, rules.email]"
                                        required
                                      >
                                        <template v-slot:prepend-inner>
                                          <inline-svg
                                            src="/media/icons/contact-gray.svg"
                                          />
                                        </template>
                                      </v-text-field>
                                    </div>

                                    <div
                                      class="col-lg-6 d-flex align-items-center"
                                    >
                                      <inline-svg
                                        src="/media/icons/help-alt-5-primary.svg"
                                      />
                                      <span class="pl-3">
                                        {{ $t("USERS.NEW_EMAIL_INFO") }}
                                      </span>
                                    </div>
                                  </div>

                                  <div class="row button-row py-3">
                                    <button
                                      class="btn btn-pill btn-white button-form button-form-primary mr-9"
                                      @click="ObserverDialog.Show = false"
                                    >
                                      {{ $t("GENERIC.CANCEL") }}
                                    </button>
                                    <button
                                      class="btn btn-pill btn-tertiary button-form"
                                      :disabled="!ObserverDialog.Valid"
                                      @click="saveObserverDialog"
                                    >
                                      {{ $t("GENERIC.OK") }}
                                    </button>
                                  </div>
                                </v-form>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                          <!--end::Observer dialog-->
                        </div>
                        <div class="col-lg-11 form-group">
                          <v-select
                            id="ObserversSelect"
                            multiple
                            v-model="Route.Observers"
                            :items="ObserverOptions"
                            chips
                            small-chips
                            deletable-chips
                            class="form-control px-2"
                            style="padding-top: 0.35em !important; height: fit-content !important"
                          ></v-select>
                        </div>
                      </div>
                      <!--end::Observers-->

                      <!--begin::InvoiceCenter-->
                      <div v-if="currentUserCompanyIsPremium" class="row">
                        <div class="col-lg-12 form-group mb-0">
                          <label>
                            {{ $t("PRICE_SEARCH.STEP3.FINISH.INVOICE_CENTER") }}
                          </label>
                        </div>
                        <div class="col-lg-1 pt-3 text-center">
                          <span
                            class="action-icon"
                            @click="openInvoiceCenterDialog"
                          >
                            <inline-svg src="/media/icons/add-primary.svg" />
                          </span>

                          <!--begin::InvoiceCenter dialog-->
                          <v-dialog
                            v-model="InvoiceCenterDialog.Show"
                            transition="dialog-bottom-transition"
                            max-width="700px"
                          >
                            <v-card>
                              <div
                                class="bg-primary text-white font-size-h5 py-3 px-7"
                              >
                                {{ $t("INVOICE_CENTERS.TITLE_ADD_2") }}
                              </div>

                              <v-card-text class="pt-6 pb-3">
                                <v-form
                                  ref="formInvoiceCenterDialog"
                                  v-model="InvoiceCenterDialog.Valid"
                                  lazy-validation
                                  class="form"
                                >
                                  <div class="row">
                                    <div class="form-group col-lg-6">
                                      <label>
                                        {{ $t("INVOICE_CENTERS.NICK") }}
                                      </label>
                                      <v-text-field
                                        class="form-control"
                                        v-model="InvoiceCenterDialog.Nick"
                                        :placeholder="
                                          $t('INVOICE_CENTERS.NICK')
                                        "
                                        required
                                        :rules="[rules.required]"
                                      />
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="form-group col-lg-6">
                                      <label>
                                        {{ $t("INVOICE_CENTERS.FISCAL_NAME") }}
                                      </label>
                                      <v-text-field
                                        class="form-control"
                                        v-model="InvoiceCenterDialog.FiscalName"
                                        :placeholder="
                                          $t('INVOICE_CENTERS.FISCAL_NAME')
                                        "
                                        required
                                        :rules="[rules.required]"
                                      />
                                    </div>

                                    <div class="form-group col-lg-6">
                                      <label>
                                        {{ $t("INVOICE_CENTERS.FISCAL_ID") }}
                                      </label>
                                      <v-text-field
                                        class="form-control"
                                        v-model="InvoiceCenterDialog.FiscalID"
                                        :placeholder="
                                          $t('INVOICE_CENTERS.FISCAL_ID')
                                        "
                                        required
                                        :rules="[rules.required]"
                                      />
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="form-group col-lg-8">
                                      <label>
                                        {{ $t("INVOICE_CENTERS.FULL_ADDRESS") }}
                                      </label>
                                      <VueGoogleAutocomplete
                                        ref="autocompleteInvoiceCenterDialogAddress"
                                        id="autocompleteInvoiceCenterDialogAddress"
                                        country="es"
                                        :fields="[
                                          'address_components',
                                          'geometry',
                                          'id',
                                          'name'
                                        ]"
                                        v-model="InvoiceCenterDialog.Street"
                                        :rules="[rules.required]"
                                        required
                                        classname="form-control"
                                        placeholder=""
                                        @placechanged="
                                          onAutocompleteInvoiceCenterDialogPlaceChanged
                                        "
                                        @inputChange="
                                          onAutocompleteInvoiceCenterDialogInputChange
                                        "
                                      />
                                      <div
                                        v-if="
                                          InvoiceCenterDialog.Street.trim()
                                            .length < 1
                                        "
                                        class="v-text-field__details pt-2 pl-3 pb-0 m-0"
                                      >
                                        <div
                                          class="v-messages theme--light error--text"
                                          role="alert"
                                        >
                                          <div class="v-messages__wrapper">
                                            <div class="v-messages__message">
                                              {{
                                                $t("VALIDATION.REQUIRED_FIELD")
                                              }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="form-group col-lg-4">
                                      <label>
                                        {{
                                          $t("INVOICE_CENTERS.POSTAL_CODE_2")
                                        }}
                                      </label>
                                      <v-text-field
                                        class="form-control"
                                        v-model="InvoiceCenterDialog.PostalCode"
                                        :placeholder="
                                          $t('COMPANIES.POSTAL_CODE')
                                        "
                                        required
                                        :rules="[rules.required]"
                                        @keyup="
                                          onInvoiceCenterDialogPostalCodeChanged
                                        "
                                      />
                                    </div>

                                    <div class="form-group col-lg-6">
                                      <label>
                                        {{ $t("INVOICE_CENTERS.CITY") }}
                                      </label>
                                      <v-text-field
                                        class="form-control"
                                        v-model="InvoiceCenterDialog.City"
                                        :placeholder="$t('COMPANIES.CITY')"
                                        required
                                        :rules="[rules.required]"
                                      />
                                    </div>

                                    <div class="form-group col-lg-6">
                                      <label>
                                        {{ $t("INVOICE_CENTERS.PROVINCE") }}
                                      </label>
                                      <select
                                        class="form-control select-down"
                                        v-model="InvoiceCenterDialog.ProvinceID"
                                        required
                                        :rules="[rules.required]"
                                      >
                                        <option
                                          v-for="p in ProvinceOptions"
                                          :key="p.ProvinceID"
                                          :value="p.ProvinceID"
                                          class="text-primary"
                                        >
                                          {{ p.Name }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="form-group col-lg-6">
                                      <label>
                                        {{
                                          $t("INVOICE_CENTERS.EMAIL_MANAGER")
                                        }}
                                      </label>
                                      <v-text-field
                                        class="form-control"
                                        v-model="
                                          InvoiceCenterDialog.EmailManager
                                        "
                                        :placeholder="
                                          $t('INVOICE_CENTERS.EMAIL_MANAGER')
                                        "
                                        required
                                        :rules="[rules.requiredEmail]"
                                      >
                                        <template v-slot:prepend-inner>
                                          <inline-svg
                                            src="/media/icons/contact-gray.svg"
                                          />
                                        </template>
                                      </v-text-field>
                                    </div>

                                    <div class="form-group col-lg-6">
                                      <label>
                                        {{
                                          $t("INVOICE_CENTERS.EMAIL_ACCOUNTING")
                                        }}
                                        <span class="font-weight-normal">
                                          ({{ $t("GENERIC.OPTIONAL") }})
                                        </span>
                                      </label>
                                      <v-text-field
                                        class="form-control"
                                        v-model="
                                          InvoiceCenterDialog.EmailAccounting
                                        "
                                        :placeholder="
                                          $t('INVOICE_CENTERS.EMAIL_ACCOUNTING')
                                        "
                                        :rules="[rules.optionalEmail]"
                                      >
                                        <template v-slot:prepend-inner>
                                          <inline-svg
                                            src="/media/icons/contact-gray.svg"
                                          />
                                        </template>
                                      </v-text-field>
                                    </div>
                                  </div>

                                  <div class="row button-row py-3">
                                    <button
                                      class="btn btn-pill btn-white button-form button-form-primary mr-9"
                                      @click="InvoiceCenterDialog.Show = false"
                                    >
                                      {{ $t("GENERIC.CANCEL") }}
                                    </button>
                                    <button
                                      class="btn btn-pill btn-tertiary button-form"
                                      :disabled="!InvoiceCenterDialog.Valid"
                                      @click="saveInvoiceCenterDialog"
                                    >
                                      {{ $t("GENERIC.OK") }}
                                    </button>
                                  </div>
                                </v-form>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                          <!--end::InvoiceCenter dialog-->
                        </div>
                        <div class="col-lg-6 form-group">
                          <select
                            class="form-control select-down"
                            v-model="Route.InvoiceCenterID"
                          >
                            <option
                              v-for="ic in InvoiceCenterOptions"
                              :key="ic.InvoiceCenterID"
                              :value="ic.InvoiceCenterID"
                              class="text-primary"
                            >
                              {{ ic.Nick }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!--end::InvoiceCenter-->
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!--end::OtherData-->

                  <!--begin::PaymentMethod-->
                  <v-expansion-panel class="white-box shadow-box p-0 mb-6">
                    <v-expansion-panel-header
                      class="manager-subtitle font-size-h4"
                    >
                      <span>
                        {{ $t("PRICE_SEARCH.STEP3.FINISH.PAYMENT_METHOD") }}
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-6 border-top-200">
                      <v-radio-group v-model="Route.PayExternal">
                        <v-radio
                          v-if="!currentUserCompanyIsPremium"
                          value="false"
                          class="ml-3"
                          style="margin-bottom: -0.25em"
                        >
                          <template v-slot:label>
                            <h4 class="mb-0 pt-2 ml-3 text-primary">
                              <strong>
                                {{
                                  $t(
                                    "PRICE_SEARCH.STEP3.FINISH.PAYMENT_INTERNAL"
                                  )
                                }}
                              </strong>
                            </h4>
                          </template>
                        </v-radio>
                        <div v-if="!currentUserCompanyIsPremium" class="row">
                          <div class="col-md-8">
                            <p class="text-dark ml-15">
                              <small>
                                {{
                                  $t(
                                    "PRICE_SEARCH.STEP3.FINISH.PAYMENT_INTERNAL_INFO_1"
                                  )
                                }}
                                <br />
                                {{
                                  $t(
                                    "PRICE_SEARCH.STEP3.FINISH.PAYMENT_INTERNAL_INFO_2"
                                  )
                                }}
                                <br />
                                {{
                                  $t(
                                    "PRICE_SEARCH.STEP3.FINISH.PAYMENT_INTERNAL_INFO_3"
                                  )
                                }}
                              </small>
                            </p>
                          </div>
                          <div class="col-md-4">
                            <v-img
                              src="/media/logos/multisafepay-logo.png"
                              style="width: 75%"
                            />
                          </div>
                        </div>

                        <v-radio
                          v-if="currentUserCompanyIsPremium"
                          value="true"
                          class="ml-3"
                          style="margin-bottom: -0.25em"
                        >
                          <template v-slot:label>
                            <h4 class="mb-0 pt-2 ml-3 text-primary">
                              <strong>
                                {{
                                  $t(
                                    "PRICE_SEARCH.STEP3.FINISH.PAYMENT_EXTERNAL"
                                  )
                                }}
                              </strong>
                            </h4>
                          </template>
                        </v-radio>
                        <p
                          v-if="currentUserCompanyIsPremium"
                          class="text-dark ml-15"
                        >
                          <small>
                            {{
                              $t(
                                "PRICE_SEARCH.STEP3.FINISH.PAYMENT_EXTERNAL_INFO"
                              )
                            }}
                          </small>
                        </p>
                      </v-radio-group>

                      <v-checkbox
                        v-model="Route.AcceptLegal"
                        required
                        :rules="[rules.required]"
                        class="ml-3"
                      >
                        <template v-slot:label>
                          <div class="pt-1 ml-3 text-dark">
                            {{ $t("GENERIC.ACCEPT_CONDITIONS_1") }}
                            <a
                              href="/legal/terms"
                              target="_blank"
                              @click.stop
                              class="font-weight-bolder"
                            >
                              {{ $t("GENERIC.ACCEPT_CONDITIONS_2") }}
                            </a>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!--end::PaymentMethod-->
                </v-expansion-panels>

                <!--begin:Submit buttons for Finish-->
                <div class="my-3 w-100 text-center">
                  <button
                    class="btn btn-pill btn-white button-form button-form-primary mr-9"
                    @click="onClickBackButton"
                  >
                    <inline-svg
                      src="/media/icons/arrow-return-primary.svg"
                      class="mr-3"
                    />
                    {{ $t("GENERIC.BACK") }}
                  </button>

                  <button
                    class="btn btn-pill btn-tertiary button-form"
                    :disabled="
                      !FormStep3ExtraFinishValid ||
                        FormStep3ExtraFinishProcessing
                    "
                    @click="onSubmitFormStep3Finish"
                  >
                    {{ $t("PRICE_SEARCH.STEP3.FINISH.CONFIRM_PAYMENT") }}
                  </button>
                </div>
                <!--end:Submit buttons for Finish-->
              </v-form>
              <!--end::Payment form-->
            </v-stepper-content>
            <!--end::Finish form-->
          </v-stepper-items>
        </v-stepper>

        <!--begin::MyVehicle dialog-->
        <v-dialog
          v-model="MyVehicleDialog.Show"
          transition="dialog-bottom-transition"
          max-width="700px"
        >
          <v-card>
            <div class="bg-primary text-white font-size-h5 py-3 px-7">
              {{ $t("PRICE_SEARCH.STEP1.IMPORT_MY_VEHICLE") }}
            </div>

            <v-card-text class="pt-6 pb-3">
              <div class="row mb-6 px-3">
                <v-text-field
                  class="form-control search-text"
                  v-model="MyVehicleDialog.Search"
                  :placeholder="$t('GENERIC.SEARCH') + '...'"
                >
                  <template v-slot:prepend-inner>
                    <inline-svg src="/media/icons/search-alt-1-primary.svg" />
                  </template>
                </v-text-field>
              </div>

              <div class="row font-size-base">
                <div
                  class="col-lg-12 mb-3 text-danger"
                  v-if="filterMyVehicleOptions().length === 0"
                >
                  {{ $t("GENERIC.NOT_FOUND") }}
                </div>

                <div
                  class="col-lg-12 mb-3"
                  v-else
                  v-for="mv in filterMyVehicleOptions()"
                  :key="mv.VehicleID"
                  style="cursor: pointer"
                  @click="saveMyVehicleDialog(mv.VehicleID)"
                >
                  <inline-svg
                    src="/media/icons/chevron-left-primary.svg"
                    class="mr-3"
                  />
                  <strong class="font-size-h4 mr-1">
                    {{ mv.Plate }}
                  </strong>
                </div>
              </div>

              <div class="row button-row py-3">
                <button
                  class="btn btn-pill btn-white button-form button-form-primary"
                  @click="MyVehicleDialog.Show = false"
                >
                  {{ $t("GENERIC.CANCEL") }}
                </button>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!--end::MyVehicle dialog-->

        <!--begin::OriginDateDesired dialog-->
        <v-dialog
          v-model="OriginDateDesiredDialog.Show"
          :return-value="OriginDateDesiredDialog.Value"
          persistent
          width="320px"
        >
          <v-date-picker
            v-model="OriginDateDesiredDialog.Value"
            color="tertiary"
            scrollable
            :locale="DateLocale"
            :first-day-of-week="DateFirstDayOfWeek"
            :min="Today"
            :max="
              Route.There.DestinationDateDesiredTo.length > 0
                ? Route.There.DestinationDateDesiredTo
                : OneYear
            "
          >
            <div class="row button-row">
              <button
                class="btn btn-pill btn-white button-form button-form-primary mr-9"
                style="min-width: 125px"
                @click="cancelOriginDateDesiredDialog"
              >
                {{ $t("GENERIC.CANCEL") }}
              </button>
              <button
                class="btn btn-pill btn-tertiary button-form"
                style="min-width: 125px"
                @click="saveOriginDateDesiredDialog"
              >
                {{ $t("GENERIC.OK") }}
              </button>
            </div>
          </v-date-picker>
        </v-dialog>
        <!--end::OriginDateDesired dialog-->
      </div>
      <!--end::Substeps forms-->

      <!--begin::Offer abstract-->
      <div class="col-lg-3">
        <div class="white-box p-0">
          <div class="bg-primary text-white p-3">
            <strong>{{ $t("PRICE_SEARCH.STEP3.OFFER_ABSTRACT") }}</strong>
          </div>
          <div class="p-3">
            <!--begin::Transporter-->
            <div class="row px-0 py-3 border-bottom-200">
              <div class="col-lg-2">
                <v-img :src="Transporter.Logo" class="companyLogo3 ml-3 mr-6" />
              </div>
              <div class="col-lg-10">
                <div class="companyName">{{ Transporter.FiscalName }}</div>
                <div>
                  {{
                    $t("PRICE_SEARCH.STEP2.FARE") +
                      " " +
                      $t("FARE_TYPE_NAMES_BY_ID." + Transporter.FareTypeID)
                  }}
                </div>
              </div>
            </div>
            <!--end::Transporter-->

            <!--begin::Route-->
            <div class="row px-0">
              <div
                class="col-lg-12 py-3 border-bottom-200"
                v-for="whichLeg in Route.Back.Enabled
                  ? ['There', 'Back']
                  : ['There']"
                :key="whichLeg"
              >
                <div class="ml-3">
                  <strong>
                    <span v-if="whichLeg === 'There'">
                      {{
                        Route.Back.Enabled
                          ? $t("PRICE_SEARCH.STEP1.THERE").toUpperCase()
                          : $t("PRICE_SEARCH.STEP1.JUST_THERE").toUpperCase()
                      }}
                    </span>
                    <span v-else>
                      {{ $t("PRICE_SEARCH.STEP1.BACK").toUpperCase() }}
                    </span>
                  </strong>
                </div>

                <v-timeline dense class="pt-3">
                  <v-timeline-item small>
                    <template v-slot:icon>
                      <inline-svg src="/media/icons/pin-1-primary.svg" />
                    </template>
                    <strong>{{ $t("PRICE_SEARCH.STEP1.ORIGIN") }}:</strong>
                    <br />
                    {{
                      whichLeg === "There"
                        ? formatExtremeAddress(Route.OriginAddress)
                        : formatExtremeAddress(Route.DestinationAddress)
                    }}
                  </v-timeline-item>
                  <v-timeline-item small>
                    <template v-slot:icon>
                      <inline-svg src="/media/icons/pin-1-primary.svg" />
                    </template>
                    <strong>
                      {{ $t("PRICE_SEARCH.STEP1.DESTINATION") }}:
                    </strong>
                    <br />
                    {{
                      whichLeg === "There"
                        ? formatExtremeAddress(Route.DestinationAddress)
                        : formatExtremeAddress(Route.OriginAddress)
                    }}
                  </v-timeline-item>
                </v-timeline>

                <!--begin::Vehicles-->
                <div class="pt-3">
                  <inline-svg
                    src="/media/icons/car-primary.svg"
                    class="ml-3 mr-3"
                  />
                  <strong>
                    {{ $t("PRICE_SEARCH.STEP1.VEHICLES") }}
                  </strong>

                  <div
                    v-if="
                      whichLeg === 'Back' &&
                        Route.Back.VehiclesStep2.length === 0
                    "
                  >
                    <p class="mt-3">
                      {{ $t("PRICE_SEARCH.STEP1.SAME_VEHICLES_2") }}
                    </p>
                  </div>

                  <div v-else>
                    <p
                      class="mt-3"
                      v-for="(vehicle2, v2) in Route[whichLeg].VehiclesStep2"
                      :key="whichLeg + '_' + v2"
                    >
                      {{
                        "x" +
                          vehicle2.Quantity +
                          ", " +
                          vehicle2.Brand +
                          ", " +
                          vehicle2.Model +
                          ", " +
                          vehicle2.Version +
                          ", " +
                          vehicle2.Year +
                          ". " +
                          VehicleTypeNames[vehicle2.VehicleTypeID] +
                          ", " +
                          VehicleStatusNames[vehicle2.VehicleStatusID]
                      }}
                    </p>
                  </div>
                </div>
                <!--end::Vehicles-->

                <!--begin::Dates-->
                <div class="pt-3">
                  <inline-svg
                    src="/media/icons/calendar-alt-1-primary.svg"
                    class="ml-3 mr-3"
                  />
                  <strong>
                    {{ $t("PRICE_SEARCH.STEP2.ESTIMATED_DELIVERY") }}
                  </strong>
                  <p class="mt-3">
                    <span
                      v-if="
                        Route[whichLeg].DestinationDateDesiredText ===
                          'ONE_WEEK'
                      "
                    >
                      {{ $t("PRICE_SEARCH.STEP1.ONE_WEEK") }}
                    </span>
                    <span
                      v-else-if="
                        Route[whichLeg].DestinationDateDesiredText ===
                          'TWO_WEEKS'
                      "
                    >
                      {{ $t("PRICE_SEARCH.STEP1.TWO_WEEKS") }}
                    </span>
                    <span
                      v-else-if="
                        Route[whichLeg].DestinationDateDesiredText ===
                          'SPECIFIC_DATE'
                      "
                    >
                      {{ Route[whichLeg].DestinationDateDesiredFromFormatted }}
                    </span>
                    <span
                      v-else-if="
                        Route[whichLeg].DestinationDateDesiredText ===
                          'SPECIFIC_RANGE'
                      "
                    >
                      {{
                        Route[whichLeg].DestinationDateDesiredFromFormatted +
                          " - " +
                          Route[whichLeg].DestinationDateDesiredToFormatted
                      }}
                    </span>
                    <span v-else>
                      {{ $t("PRICE_SEARCH.STEP1.FLEXIBLE") }}
                    </span>
                  </p>
                </div>
                <!--end::Dates-->
              </div>
            </div>
            <!--end::Route-->

            <!--begin::Details-->
            <div class="row px-3 py-3 border-bottom-200">
              <button
                v-if="!DetailsOpen"
                class="btn btn-pill btn-white button-filter miniButton px-6"
                @click="DetailsOpen = true"
              >
                <inline-svg
                  src="/media/icons/chevron-down-primary.svg"
                  class="mr-3"
                />
                {{ $t("GENERIC.DETAILS_OPEN") }}
              </button>

              <button
                v-else
                class="btn btn-pill btn-white button-filter miniButton px-6"
                @click="DetailsOpen = false"
              >
                <inline-svg
                  src="/media/icons/chevron-up-primary.svg"
                  class="mr-3"
                />
                {{ $t("GENERIC.DETAILS_CLOSE") }}
              </button>

              <div class="col-lg-12 py-3 px-3" v-if="DetailsOpen">
                <p>
                  <strong>
                    {{ $t("PRICE_SEARCH.STEP2.TRANSPORTER_DATA") }}
                  </strong>
                </p>
                <h5>
                  {{ Transporter.FiscalName }}
                  <br />
                  {{ $t("COMPANIES.FISCAL_ID_SHORT") }}
                  {{ Transporter.FiscalID }}
                </h5>
                <p>
                  <a
                    :href="formatTransporterAddressGoogleMaps()"
                    target="_blank"
                  >
                    <inline-svg
                      src="/media/icons/map-primary.svg"
                      class="mr-3"
                    />
                    {{ formatTransporterAddress() }}
                  </a>
                </p>
                <div
                  v-if="
                    Transporter.Conditions && Transporter.Conditions.length > 0
                  "
                  class="row"
                >
                  <div class="col-lg-12">
                    <p>
                      <strong>
                        {{ $t("PRICE_SEARCH.STEP2.DETAILS_SERVICE") }}
                      </strong>
                    </p>
                    <ul class="ml-6">
                      <li
                        v-for="(conditionLine, c) in Transporter.Conditions"
                        :key="c"
                      >
                        {{ conditionLine }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Details-->

            <!--begin::Price-->
            <div class="row px-6 py-3">
              <div
                class="w-100 d-flex align-items-baseline justify-content-between"
              >
                <div class="text-primary priceLine">
                  <strong>
                    {{ $t("PRICE_SEARCH.STEP3.TOTAL").toUpperCase() }}
                  </strong>
                </div>
                <div>
                  <div class="text-primary priceTotal">
                    {{ formatPrice(finalPrice, Transporter.Price.Currency) }}
                    <span v-if="!Transporter.Price.NoTaxes" class="text-danger">
                      *
                    </span>
                  </div>
                  <small v-if="!Transporter.Price.NoTaxes" class="text-danger">
                    (*) {{ $t("PRICE_SEARCH.STEP2.TAXES_INCLUDED") }}
                  </small>
                </div>
              </div>
            </div>
            <!--end::Price-->
          </div>
        </div>
      </div>
      <!--end::Offer abstract-->
    </div>
  </div>
  <!--end::PriceSearch step 3-->
</template>

<style scoped>
.miniButton {
  min-width: 0;
}
.companyLogo3 {
  border: 0;
  width: 3em !important;
  height: auto;
}
.companyName {
  font-size: 1.2em;
  font-weight: 800;
}
.priceLine {
  font-size: 1.2em;
  font-weight: 800;
}
.priceTotal {
  font-size: 1.75em !important;
  font-weight: 800;
}
.manager-subtitle {
  border-bottom: 0;
  margin-bottom: 0.5em;
}
</style>

<script>
import { mapGetters } from "vuex";
import { DO_AUTH, CHANGE_COMPANY } from "@/core/services/store/auth.module";

import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import VueGoogleAutocomplete from "vue-google-autocomplete";

import AddressService from "@/core/services/api/v2/address.service";
import CompanyClassService from "@/core/services/api/v2/companyclass.service";
import InvoiceCenterService from "@/core/services/api/v2/invoicecenter.service";
import PriceSearchService from "@/core/services/api/v2/pricesearch.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import ServiceService from "@/core/services/api/v2/service.service";
import SubscriptionTypeService from "@/core/services/api/v2/subscriptiontype.service";
import UserService from "@/core/services/api/v2/user.service";
import VehicleService from "@/core/services/api/v2/vehicle.service";
import VehicleStatusService from "@/core/services/api/v2/vehiclestatus.service";
import VehicleTypeService from "@/core/services/api/v2/vehicletype.service";

export default {
  name: "PriceSearchStep3",

  components: {
    VueGoogleAutocomplete
  },

  data() {
    return {
      SubstepThere: 1,
      SubstepBack: 2,
      SubstepRegister: 3,
      SubstepFinish: 4,
      CurrentSubstep: 1,
      ProvinceOptions: [],
      VehicleStatusNames: [],
      VehicleTypeNames: [],
      DesiredRouteIndex: -1,
      DesiredTransporterIndex: -1,
      Route: null,
      Transporter: null,
      DetailsOpen: false,
      ServiceOptions: { Origin: [], Destination: [] },
      CompanyClassOptions: [],
      ObserverOptions: [],
      InvoiceCenterOptions: [],
      PanelsStep3ExtraThereOpen: [0, 1, 2, 3],
      PanelsStep3ExtraBackOpen: [2, 3],
      PanelsStep3ExtraFinishOpen: [0, 1],
      FormStep3ExtraThereValid: false,
      FormStep3ExtraBackValid: false,
      FormStep3ExtraRegisterValid: false,
      FormStep3ExtraRegisterProcessing: false,
      FormStep3ExtraFinishValid: false,
      FormStep3ExtraFinishProcessing: false,
      MyVehicleDialog: {
        Show: false,
        WhichLeg: "There",
        WhichPosition: 0,
        List: [],
        Search: ""
      },
      OriginDateDesiredDialog: {
        Show: false,
        WhichLeg: "",
        Value: ""
      },
      ObserverDialog: {
        Show: false,
        Valid: false,
        Nick: "",
        Name: "",
        Email: "",
        NickCompany: ""
      },
      InvoiceCenterDialog: {
        Show: false,
        Valid: false,
        Nick: "",
        FiscalName: "",
        FiscalID: "",
        Street: "",
        PostalCode: "",
        City: "",
        ProvinceID: 0,
        EmailManager: "",
        EmailAccounting: ""
      },
      RegisterData: {
        Copy: false,
        CompanyClassID: 1,
        Nick: "",
        Name: "",
        Email: "",
        Phone: "",
        FiscalName: "",
        FiscalID: "",
        TradeName: "",
        BrandName: "",
        ContactName: "",
        ContactPhone: "",
        ContactEmail: "",
        Street: "",
        PostalCode: "",
        City: "",
        ProvinceID: 0,
        Accept: false,
        Done: false
      },
      RegisterSubscription: {},
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      DateFirstDayOfWeek: 1,
      DateLocale: "es-es",
      Today: "",
      OneYear: "",
      MorningStart: "09:00",
      MorningEnd: "14:00",
      AfternoonStart: "15:00",
      AfternoonEnd: "20:00",
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL"),
        requiredEmail: value => {
          if (value.length > 0 && /.+@.+\..+/.test(value)) {
            return true;
          } else {
            return this.$i18n.t("VALIDATION.INVALID_EMAIL");
          }
        },
        optionalEmail: value => {
          if (!value || value.length === 0 || /.+@.+\..+/.test(value)) {
            return true;
          } else {
            return this.$i18n.t("VALIDATION.INVALID_EMAIL");
          }
        },
        plateUniqueThere: value => {
          value = this.formatPlate(value);

          let times = 0;
          this.Route.There.Vehicles.forEach(v => {
            if (this.formatPlate(v.Plate) === value) {
              times++;
            }
          });

          if (times === 1) {
            return true;
          } else {
            return this.$i18n.t("PRICE_SEARCH.STEP3.EXTRA_DATA.PLATE_REPEATED");
          }
        },
        plateUniqueBack: value => {
          value = this.formatPlate(value);

          let times = 0;
          this.Route.Back.Vehicles.forEach(v => {
            if (this.formatPlate(v.Plate) === value) {
              times++;
            }
          });

          if (times === 1) {
            return true;
          } else {
            return this.$i18n.t("PRICE_SEARCH.STEP3.EXTRA_DATA.PLATE_REPEATED");
          }
        }
      }
    };
  },

  computed: {
    ...mapGetters(["isAuthenticated", "currentUserCompanyIsPremium"]),
    finalPrice() {
      let finalTotal = 0;

      if (this.Transporter && this.Transporter.Price) {
        // Transporter's price
        finalTotal = parseFloat(this.Transporter.Price.Total);

        // Extra services
        ["There", "Back"].forEach(whichLeg => {
          this.Route[whichLeg].Vehicles.forEach(aVehicle => {
            ["Origin", "Destination"].forEach(whichSide => {
              let validServiceOptions = [];
              if (whichLeg === "There") {
                validServiceOptions = this.ServiceOptions[whichSide];
              } else {
                // Back is reveresed from There
                validServiceOptions =
                  whichSide === "Origin"
                    ? this.ServiceOptions.Destination
                    : this.ServiceOptions.Origin;
              }

              validServiceOptions.forEach(aService => {
                if (
                  aVehicle["ServicesCheckbox" + whichSide][aService.ServiceID]
                ) {
                  finalTotal += aService.Price[aVehicle.VehicleTypeID];
                }
              });
            });
          });
        });
      }

      return Math.round(finalTotal * 100) / 100;
      // N.B: as of 2021-06 all prices are in EUR, so we use 2 decimals
    }
  },

  created() {
    this.Today = moment().format(this.DateFormatEN);
    this.OneYear = moment()
      .add(1, "years")
      .format(this.DateFormatEN);

    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFirstDayOfWeek = 0;
      this.DateLocale = "en-en";
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFirstDayOfWeek = 1;
      this.DateLocale = "es-es";
      this.DateFormatLang = this.DateFormatES;
    }

    this.loadCompanyClassOptions();
    this.loadObserverOptions();
    this.loadInvoiceCenterOptions();
  },

  mounted() {
    // Pick desired route & transporter indexes
    if (this.$route.params.routeCacheIndex) {
      this.DesiredRouteIndex = parseInt(this.$route.params.routeCacheIndex);
    } else {
      this.DesiredRouteIndex = -1;
    }

    if (this.$route.params.transporterCacheIndex) {
      this.DesiredTransporterIndex = parseInt(
        this.$route.params.transporterCacheIndex
      );
    } else {
      this.DesiredTransporterIndex = -1;
    }
  },

  methods: {
    loadCompanyClassOptions() {
      this.CompanyClassOptions = [];
      CompanyClassService.listAll().then(response => {
        this.CompanyClassOptions = response;

        this.loadProvinceOptions();
      });
    },

    loadProvinceOptions() {
      this.ProvinceOptions = [];
      ProvinceService.listAll().then(response => {
        this.ProvinceOptions = response;

        this.loadVehicleStatusNames();
      });
    },

    getProvinceName(provinceID) {
      let name = "";

      for (let p = 0; p < this.ProvinceOptions.length; p++) {
        let maybeProvince = this.ProvinceOptions[p];
        if (maybeProvince.ProvinceID === provinceID) {
          name = maybeProvince.Name;
          break;
        }
      }

      return name;
    },

    loadVehicleStatusNames() {
      this.VehicleStatusNames = [];
      VehicleStatusService.listAll().then(response => {
        response.forEach(vs => {
          this.VehicleStatusNames[vs.VehicleStatusID] = vs.Name;
        });

        this.loadVehicleTypeNames();
      });
    },

    loadVehicleTypeNames() {
      this.VehicleTypeNames = [];
      VehicleTypeService.listAll().then(response => {
        response.forEach(vt => {
          this.VehicleTypeNames[vt.VehicleTypeID] = vt.Name;
        });

        this.loadStep2CachedData();
      });
    },

    loadServiceOptionsByTransporter() {
      this.ServiceOptions = { Origin: [], Destination: [] };
      let tcID = this.Transporter.CompanyID;
      let oProvinceID = this.Route["OriginAddress"].ProvinceID;
      let dProvinceID = this.Route["DestinationAddress"].ProvinceID;

      ServiceService.listByCompanyAndProvince(tcID, oProvinceID).then(
        responseO => {
          this.ServiceOptions.Origin = responseO;

          if (dProvinceID === oProvinceID) {
            this.ServiceOptions.Destination = responseO;
          } else {
            ServiceService.listByCompanyAndProvince(tcID, dProvinceID).then(
              responseD => {
                this.ServiceOptions.Destination = responseD;
              }
            );
          }
        }
      );
    },

    formatServiceNames(whichLeg, whichSideServices, serviceIDs) {
      // "Back" origin-destination is reversed from "There"
      if (whichLeg === "Back") {
        whichSideServices =
          whichSideServices === "Origin" ? "Destination" : "Origin";
      }

      let serviceList = this.ServiceOptions[whichSideServices];

      let names = [];

      serviceIDs.forEach(sID => {
        for (let s = 0; s < serviceList.length; s++) {
          let maybeService = serviceList[s];
          if (maybeService.ServiceID === sID) {
            names.push(maybeService.Name);
            break;
          }
        }
      });

      return names.join(", ") + ".";
    },

    filterServiceOptionsByVehicleTypeID(whichLeg, whichSideServices, vtID) {
      // I.e. don't show services without price set for that VehicleType

      // "Back" origin-destination is reversed from "There"
      if (whichLeg === "Back") {
        whichSideServices =
          whichSideServices === "Origin" ? "Destination" : "Origin";
      }
      let serviceList = this.ServiceOptions[whichSideServices];

      let filtered = [];
      serviceList.forEach(maybeService => {
        if (vtID in maybeService.Price) {
          filtered.push({
            ServiceID: maybeService.ServiceID,
            Name: maybeService.Name,
            Price: maybeService.Price[vtID]
          });
        }
      });

      return filtered;
    },

    loadObserverOptions() {
      this.ObserverOptions = [];

      if (this.isAuthenticated) {
        UserService.listAllObservers(true).then(response => {
          // Parse them into v-select structure
          let list = [];
          response.forEach(o => {
            list.push({
              value: o.UserID,
              text: o.Nick + " (" + o.NickCompany + ")"
            });
          });

          // Sort by text
          list.sort((a, b) => {
            return a.text < b.text;
          });

          this.ObserverOptions = list;
        });
      }
    },

    formatObserverNames(observersID) {
      let names = [];

      observersID.forEach(oID => {
        for (let o = 0; o < this.ObserverOptions.length; o++) {
          let maybeObserver = this.ObserverOptions[o];
          if (maybeObserver.value === oID) {
            names.push(maybeObserver.text);
            break;
          }
        }
      });

      return names.join(", ") + ".";
    },

    loadInvoiceCenterOptions() {
      if (this.isAuthenticated && this.currentUserCompanyIsPremium) {
        InvoiceCenterService.listAll().then(response => {
          let list = [];
          list.push({
            InvoiceCenterID: "",
            Nick: ""
          });

          response.forEach(ic => {
            list.push({
              InvoiceCenterID: ic.InvoiceCenterID,
              Nick: ic.Nick
            });
          });

          this.InvoiceCenterOptions = list;
        });
      }
    },

    getInvoiceCenterNick(invoiceCenterID) {
      let nick = "";

      for (let i = 0; i < this.InvoiceCenterOptions.length; i++) {
        let maybeIC = this.InvoiceCenterOptions[i];
        if (maybeIC.InvoiceCenterID === invoiceCenterID) {
          nick = maybeIC.Nick;
          break;
        }
      }

      return nick;
    },

    loadStep2CachedData() {
      this.Route = null;
      this.Transporter = null;

      let routesList = PriceSearchService.getCachedRoutes()["Routes"];
      if (routesList.length > 0 && routesList.length > this.DesiredRouteIndex) {
        this.Route = routesList[this.DesiredRouteIndex];
      }

      let resultsList = PriceSearchService.getCachedResults()["Routes"];
      if (
        resultsList.length > 0 &&
        resultsList.length > this.DesiredRouteIndex
      ) {
        let routeResultsList =
          resultsList[this.DesiredRouteIndex]["Transporters"];

        if (routeResultsList.length > this.DesiredTransporterIndex) {
          this.Transporter = routeResultsList[this.DesiredTransporterIndex];
        }
      }

      if (!this.Route || !this.Transporter) {
        this.$router.push("/pricesearch/step2");
      } else {
        // Load "that" Transporter extra services
        this.loadServiceOptionsByTransporter();

        // Add extra fields for address contact - download from server if it's a FrequentAddress
        ["OriginAddress", "DestinationAddress"].forEach(whichSide => {
          this.Route[whichSide].SaveAddress = false;
          this.Route[whichSide].ContactName = "";
          this.Route[whichSide].ContactEmail = "";
          this.Route[whichSide].ContactPhone = "";

          if (this.isAuthenticated) {
            let aID = parseInt(this.Route[whichSide].AddressID);
            if (aID > 0) {
              AddressService.getProfile(aID).then(response => {
                if (response.AddressID) {
                  this.Route[whichSide] = Object.assign({}, response);
                }
              });
            }
          }
        });

        // Add extra fields for dates
        ["There", "Back"].forEach(whichLeg => {
          if (whichLeg === "There") {
            this.Route[whichLeg].OriginDateDesired = moment().format(
              this.DateFormatEN
            );
            this.Route[whichLeg].OriginDateDesiredFormatted = moment().format(
              this.DateFormatLang
            );
          } else {
            this.setOriginDateDesiredBackWhenThere();
          }

          this.Route[whichLeg].DestinationDateDesiredTimeText = "ALL_DAY";
          this.Route[whichLeg].DestinationDateDesiredTimeFrom = "";
          this.Route[whichLeg].DestinationDateDesiredTimeTo = "";

          // Split Vehicles array per quantity of each line
          // including those imported from My Vehicles
          let vehiclesStep2 = [];
          let vehiclesStep3 = [];

          this.Route[whichLeg].Vehicles.forEach(v => {
            let vModel = v.VehicleModelID;
            let vStatus = v.VehicleStatusID;
            let repeatedPosition = -1;

            for (let index = 0; index < vehiclesStep2.length; index++) {
              let oldVehicle = vehiclesStep2[index];
              if (
                oldVehicle.VehicleModelID === vModel &&
                oldVehicle.VehicleStatusID === vStatus
              ) {
                repeatedPosition = index;
                break;
              }
            }

            if (repeatedPosition > -1) {
              vehiclesStep2[repeatedPosition]["Quantity"] += v["Quantity"];
            } else {
              vehiclesStep2.push(v);
            }

            for (let q = 1; q <= v.Quantity; q++) {
              vehiclesStep3.push({
                VehicleID: v.VehicleID ? v.VehicleID : null,
                VehicleModelID: v.VehicleModelID,
                VehicleStatusID: v.VehicleStatusID,
                VehicleTypeID: v.VehicleTypeID,
                Plate: v.Plate ? v.Plate : "",
                Year: v.Year ? v.Year : "",
                ServicesOrigin: [],
                ServicesDestination: [],
                ServicesCheckboxOrigin: [],
                ServicesCheckboxDestination: []
              });
            }
          });

          this.Route[whichLeg].Vehicles = vehiclesStep3;
          this.Route[whichLeg].VehiclesStep2 = vehiclesStep2;
          this.Route[whichLeg].SaveVehicles = false;
        });

        // If it had no vehicles for Back, i.e. "the same as There",
        // copy them (for Extra Services selection)
        if (!this.Route.Back.Enabled) {
          this.Route.Back.Vehicles = [];
        } else if (this.Route.Back.Vehicles.length === 0) {
          let vehiclesStep2 = [];
          let vehiclesStep3 = [];

          this.Route.There.VehiclesStep2.forEach(vThere => {
            vehiclesStep2.push(vThere);

            for (let q = 1; q <= vThere.Quantity; q++) {
              vehiclesStep3.push({
                VehicleID: vThere.VehicleID ? vThere.VehicleID : null,
                VehicleModelID: vThere.VehicleModelID,
                VehicleStatusID: vThere.VehicleStatusID,
                VehicleTypeID: vThere.VehicleTypeID,
                Plate: vThere.Plate ? vThere.Plate : "",
                Year: vThere.Year ? vThere.Year : 0,
                ServicesOrigin: [],
                ServicesDestination: [],
                ServicesCheckboxOrigin: [],
                ServicesCheckboxDestination: []
              });
            }
          });

          this.Route.Back.VehiclesStep2 = vehiclesStep2;
          this.Route.Back.Vehicles = vehiclesStep3;
        }

        // Other extra fields
        this.Route.InvoiceCenterID = null;
        this.Route.Observers = [];
        this.Route.PayExternal = this.currentUserCompanyIsPremium
          ? "true"
          : "false";

        // Substeps index:
        // 1. ExtraData for There
        this.SubstepThere = 1;

        // 2. ExtraData for Back (if enabled)
        this.SubstepBack = this.Route.Back.Enabled ? this.SubstepThere + 1 : 99;

        // 3. RegisterData (if needed)
        if (this.isAuthenticated && !this.RegisterData.Done) {
          this.SubstepRegister = 999;
        } else {
          this.SubstepRegister = this.Route.Back.Enabled
            ? this.SubstepBack + 1
            : this.SubstepThere + 1;

          this.loadRegisterSubscription();
        }

        // 4. Finish
        if (this.SubstepRegister < 10) {
          this.SubstepFinish = this.SubstepRegister + 1;
        } else {
          this.SubstepFinish = this.Route.Back.Enabled
            ? this.SubstepBack + 1
            : this.SubstepThere + 1;
        }

        // Force "required field" messages
        this.$nextTick(() => {
          this.$refs.formStep3ExtraThere.validate();
        });
      }
    },

    searchVehicleStep3IntoStep2(whichLeg, whichPositionStep3) {
      let v3 = this.Route[whichLeg].Vehicles[whichPositionStep3];
      let v2Info = "";

      for (let v = 0; v < this.Route[whichLeg].VehiclesStep2.length; v++) {
        let maybe2 = this.Route[whichLeg].VehiclesStep2[v];
        if (
          v3.VehicleModelID === maybe2.VehicleModelID &&
          v3.VehicleStatusID === maybe2.VehicleStatusID
        ) {
          v2Info =
            maybe2.Brand +
            ", " +
            maybe2.Model +
            ", " +
            maybe2.Version +
            ", " +
            maybe2.Year;

          break;
        }
      }

      return v2Info;
    },

    formatPrice(value, currency) {
      if (!value) {
        return "";
      } else {
        let value2 = value.toFixed(2);
        let userLang = i18nService.getActiveLanguage();
        if (userLang === "en") {
          return value2 + " " + currency;
        } else {
          return value2.replace(".", ",") + " " + currency;
        }
      }
    },
    formatServicePrice(value, currency) {
      if (value === 0) {
        return this.$t("GENERIC.PRICE_FREE");
      } else {
        return this.formatPrice(value, currency);
      }
    },

    formatExtremeAddress(a) {
      return (
        a.PostalCode +
        " - " +
        a.City +
        " (" +
        this.getProvinceName(a.ProvinceID) +
        ")"
      );
    },

    formatTransporterAddress() {
      if (!this.Transporter) {
        return "";
      } else {
        return (
          this.Transporter.Street +
          ". " +
          this.Transporter.PostalCode +
          " - " +
          this.Transporter.City +
          " (" +
          this.getProvinceName(this.Transporter.ProvinceID) +
          ")"
        );
      }
    },

    formatTransporterAddressGoogleMaps() {
      if (!this.Transporter) {
        return "";
      } else {
        let address =
          this.Transporter.Street +
          ", " +
          this.Transporter.PostalCode +
          ", " +
          this.Transporter.City;

        return (
          "https://www.google.es/maps/place/" + address.replaceAll(" ", "+")
        );
      }
    },

    formatPlate(aPlate) {
      // To compare vehicle Plate uniqueness, this function strips non-alphanumeric values
      // ex. "VA 9876 BC" = "VA-9876-BC" = "VA9876BC"
      return aPlate.replaceAll(/[^A-Za-z0-9]/g, "").toUpperCase();
    },

    onKeyupVehiclePlate(whichLeg, whichPosition) {
      this.Route[whichLeg].Vehicles[whichPosition].VehicleID = "";

      // Keep "Back" sync'ed with "There", if needed
      if (
        whichLeg === "There" &&
        this.Route.Back.Enabled &&
        this.Route.Back.SameVehicles
      ) {
        this.Route.Back.Vehicles[whichPosition].VehicleID = "";
        this.Route.Back.Vehicles[
          whichPosition
        ].Plate = this.Route.There.Vehicles[whichPosition].Plate;
      }
    },

    openMyVehicleDialog(whichLeg, whichPosition, event) {
      event.preventDefault();
      event.stopPropagation();

      // Download the list of valid vehicles: of that line's model & status,
      // active and not travelling
      VehicleService.search(
        "",
        "",
        this.Route[whichLeg].Vehicles[whichPosition].VehicleStatusID,
        this.Route[whichLeg].Vehicles[whichPosition].VehicleModelID,
        "",
        "",
        "",
        "true",
        "false",
        "Plate",
        false,
        999,
        0,
        false
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.MyVehicleDialog.List = [];
        } else {
          this.MyVehicleDialog.List = rawResponse.response.Vehicles;
        }

        this.MyVehicleDialog.WhichLeg = whichLeg;
        this.MyVehicleDialog.WhichPosition = whichPosition;
        this.MyVehicleDialog.Search = "";
        this.MyVehicleDialog.Show = true;
      });
    },

    filterMyVehicleOptions() {
      // Avoid those already selected on this leg
      let alreadySelectedID = [];
      let alreadySelectedPlate = [];
      this.Route[this.MyVehicleDialog.WhichLeg].Vehicles.forEach(av => {
        alreadySelectedID.push(av.VehicleID);
        alreadySelectedPlate.push(this.formatPlate(av.Plate));
      });

      // And filter by text (on Plate, which is UpperCased by this.formatPlate())
      let searchText = this.MyVehicleDialog.Search.trim().toUpperCase();

      if (
        alreadySelectedID.length === 0 &&
        alreadySelectedPlate.length === 0 &&
        searchText.length === 0
      ) {
        return this.MyVehicleDialog.List;
      } else {
        return this.MyVehicleDialog.List.filter(mv => {
          let aPlate = this.formatPlate(mv.Plate);

          if (
            alreadySelectedID.includes(mv.VehicleID) ||
            alreadySelectedPlate.includes(aPlate)
          ) {
            return false;
          } else {
            return mv.Plate.includes(searchText);
          }
        });
      }
    },

    saveMyVehicleDialog(whichVehicleID) {
      for (let v = 0; v < this.MyVehicleDialog.List.length; v++) {
        let mv = this.MyVehicleDialog.List[v];

        if (mv.VehicleID === whichVehicleID) {
          let whichLeg = this.MyVehicleDialog.WhichLeg;
          let whichPosition = this.MyVehicleDialog.WhichPosition;

          this.Route[whichLeg].Vehicles[whichPosition].VehicleID = mv.VehicleID;
          this.Route[whichLeg].Vehicles[whichPosition].Plate = mv.Plate;

          // Keep "Back" sync'ed with "There", if needed
          if (
            whichLeg === "There" &&
            this.Route.Back.Enabled &&
            this.Route.Back.SameVehicles
          ) {
            this.Route.Back.Vehicles[whichPosition].VehicleID = mv.VehicleID;
            this.Route.Back.Vehicles[whichPosition].Plate = mv.Plate;
          }

          break;
        }
      }

      this.MyVehicleDialog.Show = false;
    },

    onClickServiceCheckbox(whichLeg, whichPosition, whichSideServices) {
      let checkedServices = [];
      this.Route[whichLeg].Vehicles[whichPosition][
        "ServicesCheckbox" + whichSideServices
      ].forEach((checked, serviceID) => {
        if (checked) {
          checkedServices.push(serviceID);
        }
      });

      this.Route[whichLeg].Vehicles[whichPosition][
        "Services" + whichSideServices
      ] = checkedServices;
    },

    openOriginDateDesiredDialog(whichLeg) {
      this.OriginDateDesiredDialog.WhichLeg = whichLeg;

      let currentDate = this.Route[whichLeg].OriginDateDesired;
      if (whichLeg === "Back" && parseInt(currentDate.substring(0, 4)) < 2000) {
        // Special value for "when There arrives" - but now we want a specific date
        currentDate = moment().format(this.DateFormatEN);
      }

      this.OriginDateDesiredDialog.Value = currentDate;

      this.OriginDateDesiredDialog.Show = true;
    },

    saveOriginDateDesiredDialog() {
      let whichLeg = this.OriginDateDesiredDialog.WhichLeg;
      let newDate = this.OriginDateDesiredDialog.Value;
      let newDateFormatted = moment(newDate).format(this.DateFormatLang);

      this.Route[whichLeg].OriginDateDesired = newDate;
      this.Route[whichLeg].OriginDateDesiredFormatted = newDateFormatted;

      this.OriginDateDesiredDialog.Show = false;
    },

    cancelOriginDateDesiredDialog() {
      // Reset picker to old date
      let whichLeg = this.OriginDateDesiredDialog.WhichLeg;
      let oldDate = this.Route[whichLeg].OriginDateDesired;

      if (whichLeg === "Back" && parseInt(oldDate.substring(0, 4)) < 2000) {
        this.setOriginDateDesiredBackWhenThere();
      } else {
        this.OriginDateDesiredDialog.Value = oldDate;
      }

      this.OriginDateDesiredDialog.Show = false;
    },

    changeOriginDateDesiredBack(event) {
      if (event.screenX === 0 && event.screenY === 0) {
        // i.e. event=click on option, not open the selector
        if (this.Route.Back.OriginDateDesiredText === "WHEN_THERE") {
          this.setOriginDateDesiredBackWhenThere();
        } else {
          this.openOriginDateDesiredDialog("Back");
        }
      }
    },

    setOriginDateDesiredBackWhenThere() {
      // Special value
      this.Route.Back = Object.assign({}, this.Route.Back, {
        OriginDateDesired: "0001-01-01",
        OriginDateDesiredFormatted: "",
        OriginDateDesiredText: "WHEN_THERE"
      });
    },

    formatDestinationDates(whichLeg) {
      let value = "";

      if (this.Route[whichLeg]) {
        let from = this.Route[whichLeg].DestinationDateDesiredFromFormatted;
        let to = this.Route[whichLeg].DestinationDateDesiredToFormatted;

        if (from === to) {
          value =
            from.length > 0
              ? from
              : this.$i18n.t("PRICE_SEARCH.STEP1.FLEXIBLE");
        } else {
          value = from + " - " + to;
        }
      }

      return value;
    },

    saveDestinationDateDesiredTime(whichLeg) {
      let from = "";
      let to = "";

      let checkedValue = this.Route[whichLeg].DestinationDateDesiredTimeText;
      if (checkedValue === "MORNING") {
        from = this.MorningStart;
        to = this.MorningEnd;
      } else if (checkedValue === "AFTERNOON") {
        from = this.AfternoonStart;
        to = this.AfternoonEnd;
      }

      this.Route[whichLeg].DestinationDateDesiredTimeFrom = from;
      this.Route[whichLeg].DestinationDateDesiredTimeTo = to;
    },

    openObserverDialog() {
      this.ObserverDialog.Nick = "";
      this.ObserverDialog.NickCompany = "";
      this.ObserverDialog.Name = "";
      this.ObserverDialog.Email = "";
      this.ObserverDialog.Show = true;

      // Force "required field" messages
      this.$nextTick(() => {
        this.$refs.formObserverDialog.validate();
      });
    },

    saveObserverDialog(e) {
      e.preventDefault();

      if (this.$refs.formObserverDialog.validate()) {
        // Disable submit button
        this.ObserverDialog.Valid = false;

        // Send request to create new Observer: we get back his ID
        UserService.createObserverProfile(this.ObserverDialog).then(
          response => {
            if (response.UserID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.Route.Observers.push(response.UserID);
              this.loadObserverOptions();
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 409) {
                whyError = this.$i18n.t("OBSERVERS.ERROR_409");
              } else if (response === 418) {
                whyError = this.$i18n.t("OBSERVERS.ERROR_418");
              } else if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button and hide dialog
            this.ObserverDialog.Valid = true;
            this.ObserverDialog.Show = false;
          }
        );
      }
    },

    openInvoiceCenterDialog() {
      this.InvoiceCenterDialog.Nick = "";
      this.InvoiceCenterDialog.FiscalName = "";
      this.InvoiceCenterDialog.FiscalID = "";
      this.InvoiceCenterDialog.Street = "";
      this.InvoiceCenterDialog.PostalCode = "";
      this.InvoiceCenterDialog.City = "";
      this.InvoiceCenterDialog.ProvinceID = 0;
      this.InvoiceCenterDialog.EmailManager = "";
      this.InvoiceCenterDialog.EmailAccounting = "";
      this.InvoiceCenterDialog.Show = true;

      // Force "required field" messages
      this.$nextTick(() => {
        this.$refs.formInvoiceCenterDialog.validate();
      });
    },

    saveInvoiceCenterDialog(e) {
      e.preventDefault();

      if (this.$refs.formInvoiceCenterDialog.validate()) {
        // Disable submit button
        this.InvoiceCenterDialog.Valid = false;

        // Send request to create new InvoiceCenter we get back his ID
        InvoiceCenterService.createProfile(this.InvoiceCenterDialog).then(
          response => {
            if (response.InvoiceCenterID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.Route.InvoiceCenterID = response.InvoiceCenterID;
              this.loadInvoiceCenterOptions();
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 409) {
                whyError = this.$i18n.t("INVOICE_CENTERS.ERROR_409");
              } else if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button and hide dialog
            this.InvoiceCenterDialog.Valid = true;
            this.InvoiceCenterDialog.Show = false;
          }
        );
      }
    },

    onSubmitFormStep3ExtraThere(e) {
      e.preventDefault();

      if (this.$refs.formStep3ExtraThere.validate()) {
        // Go to next substep:
        // ExtraData for Back (if enabled)
        // or RegisterData (if needed)
        // or Finish
        // And force "required field" messages
        if (this.Route.Back.Enabled) {
          this.CurrentSubstep = this.SubstepBack;
          this.$nextTick(() => {
            this.$refs.formStep3ExtraBack.validate();
          });
        } else if (!this.isAuthenticated) {
          this.CurrentSubstep = this.SubstepRegister;
          this.$nextTick(() => {
            this.$refs.formStep3ExtraRegister.validate();
          });
        } else {
          this.CurrentSubstep = this.SubstepFinish;
          this.$nextTick(() => {
            this.$refs.formStep3ExtraFinish.validate();
          });
        }

        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 10);
      }
    },

    onSubmitFormStep3ExtraBack(e) {
      e.preventDefault();

      if (this.$refs.formStep3ExtraBack.validate()) {
        // Go to next substep:
        // RegisterData (if needed)
        // or Finish
        // And force "required field" messages
        if (!this.isAuthenticated) {
          this.CurrentSubstep = this.SubstepRegister;
          this.$nextTick(() => {
            this.$refs.formStep3ExtraRegister.validate();
          });
        } else {
          this.CurrentSubstep = this.SubstepFinish;
          this.$nextTick(() => {
            this.$refs.formStep3ExtraFinish.validate();
          });
        }

        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 10);
      }
    },

    loadRegisterSubscription() {
      SubscriptionTypeService.getSenderBasic().then(subscri => {
        let formattedPrice = "";
        let price = parseFloat(subscri.PricePerMonth);
        if (price === 0) {
          formattedPrice =
            "<strong>" + this.$t("GENERIC.PRICE_FREE") + "</strong>";
        } else {
          let months = subscri.Months;
          price = price * months;
          formattedPrice =
            "<strong>" +
            price.toFixed(2).replace(".", ",") +
            " €</strong> / " +
            this.$tc("SUBSCRIPTION_TYPES.PER_MONTHS", months, {
              count: months
            });
        }

        this.RegisterSubscription = {
          Name:
            this.$i18n.t("COMPANY_TYPE_NAMES_BY_ID.2") + " - " + subscri.Name,
          Description: subscri.Description,
          Price: formattedPrice
        };
      });
    },

    onClickRegisterCopyCheckbox() {
      if (this.RegisterData.Copy) {
        this.RegisterData.Name = this.RegisterData.ContactName = this.Route.OriginAddress.ContactName;

        this.RegisterData.Email = this.RegisterData.ContactEmail = this.Route.OriginAddress.ContactEmail;

        this.RegisterData.Phone = this.RegisterData.ContactPhone = this.Route.OriginAddress.ContactPhone;

        this.RegisterData.TradeName = this.RegisterData.FiscalName = this.RegisterData.BrandName = this.Route.OriginAddress.TradeName;

        this.RegisterData.Street = this.Route.OriginAddress.Street;
        this.RegisterData.PostalCode = this.Route.OriginAddress.PostalCode;
        this.RegisterData.City = this.Route.OriginAddress.City;
        this.RegisterData.ProvinceID = this.Route.OriginAddress.ProvinceID;
      }
    },

    onAutocompleteRegisterPlaceChanged(addressData) {
      this.onAutocompletePlaceChanged("RegisterData", addressData);
    },

    onAutocompleteInvoiceCenterDialogPlaceChanged(addressData) {
      this.onAutocompletePlaceChanged("InvoiceCenterDialog", addressData);
    },

    onAutocompletePlaceChanged(whichBlock, addressData) {
      let newStreet =
        addressData.route +
        (addressData.street_number ? " " + addressData.street_number : "");
      this[whichBlock].Street = newStreet;

      this[whichBlock].PostalCode = addressData.postal_code;
      this[whichBlock].City = addressData.locality;
      this.onPostalCodeChanged(whichBlock);

      // Clean extra values from Street box
      this.$refs[
        "autocomplete" + whichBlock + "Address"
      ].$refs.autocomplete.value = newStreet;
    },

    onAutocompleteRegisterInputChange(e) {
      if (e.newVal.trim().length === 0) {
        this.RegisterData.Street = "";
      }
    },

    onAutocompleteInvoiceCenterDialogInputChange(e) {
      if (e.newVal.trim().length === 0) {
        this.InvoiceCenterDialog.Street = "";
      }
    },

    onRegisterPostalCodeChanged() {
      this.onPostalCodeChanged("RegisterData");
    },

    onInvoiceCenterDialogPostalCodeChanged() {
      this.onPostalCodeChanged("InvoiceCenterDialog");
    },

    onPostalCodeChanged(whichBlock) {
      // Convert PostalCode to an item in our ProvinceID selector
      // that's why we restrict VueGoogleAutocomplete component to country="es"
      let newPostalCode = parseInt(this[whichBlock].PostalCode);
      let newProvinceID = 1;

      for (let p = 0; p < this.ProvinceOptions.length; p++) {
        let maybeProvince = this.ProvinceOptions[p];
        let pcStart = parseInt(maybeProvince.PostalCodeStart);
        let pcEnd = parseInt(maybeProvince.PostalCodeEnd);
        if (newPostalCode >= pcStart && newPostalCode <= pcEnd) {
          newProvinceID = maybeProvince.ProvinceID;
          break;
        }
      }

      this[whichBlock].ProvinceID = newProvinceID;
    },

    onSubmitFormStep3Register(e) {
      e.preventDefault();

      if (
        this.RegisterData.Accept &&
        this.$refs.formStep3ExtraRegister.validate()
      ) {
        // Disable submit button
        this.FormStep3ExtraRegisterProcessing = true;

        // User profile pages require his Nick,
        // but as of 2021-11 this form doesn't ask for it - fill with Name
        if (this.RegisterData.Nick.length < 1) {
          this.RegisterData.Nick = this.RegisterData.Name;
        }

        // TradeName is also required on Company profile pages,
        // but this form allows it to be empty on non-Enterprises
        if (this.RegisterData.TradeName.length < 1) {
          this.RegisterData.TradeName = this.RegisterData.FiscalName;
        }

        // Send request
        PriceSearchService.register(this.RegisterData).then(rawResponse => {
          if (!rawResponse.Token) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");

            if (rawResponse === 409) {
              whyError = this.$i18n.t("REGISTER.ERROR_409");
            } else if (rawResponse === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });
          } else {
            // Pick login data @see pages/auth/Login.vue -> onSubmitLogin()
            this.$store.dispatch(DO_AUTH, rawResponse);
            this.$store.dispatch(CHANGE_COMPANY, rawResponse.Companies[0]);

            // Go to next substep: Finish and force "required fields" messages
            this.RegisterData.Done = true;
            this.CurrentSubstep = this.SubstepFinish;
            this.$nextTick(() => {
              this.$refs.formStep3ExtraFinish.validate();
            });

            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 10);
          }

          // Reset submit button
          setTimeout(() => {
            this.FormStep3ExtraRegisterProcessing = false;
          }, 1000);
        });
      }
    },

    onSubmitFormStep3Finish(e) {
      e.preventDefault();

      if (this.$refs.formStep3ExtraFinish.validate()) {
        // Disable submit button
        this.FormStep3ExtraFinishProcessing = true;

        // Send request: we get back the server-computed final price
        PriceSearchService.purchase(
          this.DesiredRouteIndex,
          this.Transporter.CompanyID,
          this.Route
        ).then(rawResponse => {
          // Reset submit button and go back/forth
          this.FormStep3ExtraFinishProcessing = false;

          if (Number.isInteger(rawResponse)) {
            // Something went wrong
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            let newPushRoute = "";
            let newCurrentSubstep = 0;

            if (rawResponse === 406) {
              whyError = this.$i18n.t("PRICE_SEARCH.STEP3.FINISH.ERROR_406");
              newPushRoute = "/pricesearch/step1";
            } else if (rawResponse === 410) {
              whyError = this.$i18n.t("PRICE_SEARCH.STEP3.FINISH.ERROR_410");
              newPushRoute = "/pricesearch/step2";
            } else if (rawResponse === 418) {
              whyError = this.$i18n.t("PRICE_SEARCH.STEP3.FINISH.ERROR_418");
              newPushRoute = "/pricesearch/step2";
            } else if (rawResponse === 409) {
              whyError = this.$i18n.t("PRICE_SEARCH.STEP3.FINISH.ERROR_409");
              this.CurrentSubstep = this.SubstepThere;
            } else if (rawResponse === 411) {
              whyError = this.$i18n.t("PRICE_SEARCH.STEP3.FINISH.ERROR_411");
              this.CurrentSubstep = this.SubstepThere;
            } else if (rawResponse === 415) {
              whyError = this.$i18n.t("PRICE_SEARCH.STEP3.FINISH.ERROR_415");
              this.CurrentSubstep = this.SubstepBack;
            } else if (rawResponse === 402) {
              whyError = this.$i18n.t("PRICE_SEARCH.STEP3.FINISH.ERROR_402");
            } else if (rawResponse === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });

            if (newCurrentSubstep > 0) {
              this.currentSubstep = newCurrentSubstep;
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 10);
            } else if (newPushRoute.length > 0) {
              setTimeout(() => this.$router.push(newPushRoute), 2000);
            }
          } else {
            // Correcly purchased: must pay, or just go to results screen?
            if (rawResponse.PaymentUrl) {
              window.location.href = rawResponse.PaymentUrl;
            } else if (rawResponse.ShipmentID) {
              this.$router.push(
                "/pricesearch/purchaseOK/" + rawResponse.ShipmentID
              );
            }
          }
        });
      }
    },

    onClickBackButton(e) {
      e.preventDefault();

      switch (this.CurrentSubstep) {
        case this.SubstepThere: {
          // At ExtraData for There -> go to previous screen
          this.$router.push("/pricesearch/step2");
          break;
        }
        case this.SubstepBack: {
          // At ExtraData for Back -> go to There
          this.CurrentSubstep = this.SubstepThere;
          break;
        }
        case this.SubstepRegister: {
          // At RegisterData -> go to Back (if enabled) or There
          this.CurrentSubstep = this.Route.Back.Enabled
            ? this.SubstepBack
            : this.SubstepThere;
          break;
        }
        case this.SubstepFinish: {
          // At Finish -> go to Back (if enabled) or There, because can't register again
          this.CurrentSubstep = this.Route.Back.Enabled
            ? this.SubstepBack
            : this.SubstepThere;
          break;
        }
        default: {
          // Unknown -> new search
          PriceSearchService.cleanCachedLists();
          this.$router.push("/pricesearch/step1");
        }
      }
    }
  }
};
</script>
